import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ResetPasswordService {
  baseUrl: string = environment.serverUrl;
  constructor(private http: HttpClient) {}

  resetPassword(passwordsObj): Observable<HttpResponse<any>> {
    return this.http.put<any>(
      `${this.baseUrl}/api/reset_password/${passwordsObj.token}`,
      {
        password: passwordsObj.password,
        confirm_password: passwordsObj.confirm_password,
        email: passwordsObj.email,
      },
      { observe: "response" }
    );
  }
}
