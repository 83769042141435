//****agregue ViewChild
import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
/*import { ObservableMedia } from '@angular/flex-layout';
import { Observable, Subject } from 'rxjs';*/
import { EditLateFeeComponent } from "src/app/_common/edit-late-fee/edit-late-fee.component";
import "jquery-slimscroll";

import * as globals from "../globals.json";
import { LateFeeService } from "../_services/late-fees.service";
//*****agregando import pick y moment
import { DateRangePickerComponent } from "ng-pick-daterange";
import * as moment from "moment";
import { User, Status } from "../_models";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-late-fees",
  templateUrl: "./late-fees.component.html",
  styleUrls: ["./late-fees.component.css"],
})
export class LateFeesComponent implements OnInit {
  public globals: any = globals.default;
  user: User;
  totalPages: Number;
  public bsModalRef: BsModalRef;
  public latefees: any[] = [];
  public loading: boolean = false;
  public paginationTotalUsers: number = 0;
  public paginationCurrentPage: number = 1;
  public paginationPageLimit: number = 10;
  public paginationMaxSize: number = 5;
  public waived: string = "";
  public domRange: any;
  public infoLateFee: any = {};

  // private searchTerms = new Subject<string>();
  status: Status[] = [];
  public filters: any = {
    search: "",
    status_id: Number,
    from_date: String,
    to_date: String,
    page: this.paginationCurrentPage,
    limit: this.paginationPageLimit,
  };
  public searchBoxPlaceHolder = "Search";
  //****agregando el datapicker
  @ViewChild("datePicker") private datePicker: DateRangePickerComponent;
  @ViewChild("datePicker") dateRangeDOM;
  public filterPlaceholder = "Search";

  constructor(
    private LS: LateFeeService,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.domRange = this.dateRangeDOM.elementRef.nativeElement;
    this.filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("week").format("YYYY-MM-DD");
    this.loading = true;
    // this.getAllLateFees();
    //agregando valor por defecto para el filtro de una semana
    this.filters.from_date = moment().startOf("week").format("YYYY-MM-DD");
    this.filters.to_date = moment().endOf("week").format("YYYY-MM-DD");
    this.user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.filterPlaceholder = "Buscar";
      this.domRange.getElementsByClassName("yk-label")[0].innerText = "Inicio";
      this.domRange.getElementsByClassName("yk-label")[1].innerText = "Fin";
      this.domRange.getElementsByClassName("yk-btn")[0].innerText = "Este Mes";
      this.domRange.getElementsByClassName("yk-btn")[1].innerText =
        "Ultimo Mes";
      this.domRange.getElementsByClassName("yk-btn")[2].innerText =
        "Esta Semana";
      this.domRange.getElementsByClassName("yk-btn")[3].innerText =
        "Ultima Semana";
      this.domRange.getElementsByClassName("yk-btn")[4].innerText = "Este Año";
      this.domRange.getElementsByClassName("yk-btn")[5].innerText =
        "Ultimo Año";
    }

    this.getInfoUserLateFee();
  }
  ngAfterViewInit(): void {
    this.filters.search = " ";
    this.filters.status_id = Number(0);
    this.setDateRange({
      from: moment().startOf("week").format("YYYY-MM-DD"),
      to: moment().endOf("week").format("YYYY-MM-DD"),
    });

    this.submitFilters(this.filters);

    if (window.location.href.includes("/es/")) {
      this.domRange.getElementsByClassName("yk-weekday")[0].innerText = "Dom";
      this.domRange.getElementsByClassName("yk-weekday")[1].innerText = "Lun";
      this.domRange.getElementsByClassName("yk-weekday")[2].innerText = "Mar";
      this.domRange.getElementsByClassName("yk-weekday")[3].innerText = "Mie";
      this.domRange.getElementsByClassName("yk-weekday")[4].innerText = "Jue";
      this.domRange.getElementsByClassName("yk-weekday")[5].innerText = "Vie";
      this.domRange.getElementsByClassName("yk-weekday")[6].innerText = "Sab";
    }
  }

  //***agregando el data range
  setDateRange(range) {
    this.filters.from_date = moment(range.from).format("YYYY-MM-DD");
    this.filters.to_date = moment(range.to).format("YYYY-MM-DD");
  }
  //**agregando el evento del boton
  submitFilters(filters) {
    this.loading = true;
    // this.paginationCurrentPage = 1;
    this.filters.from_date = moment(
      this.domRange.getElementsByClassName("yk-content")[0].innerText,
      "MMM D, YYYY"
    ).format("YYYY-MM-DD");
    this.filters.to_date = moment(
      this.domRange.getElementsByClassName("yk-content")[1].innerText,
      "MMM D, YYYY"
    ).format("YYYY-MM-DD");
    // this.filters.from_date = moment(filters.from_date).format('YYYY-MM-DD');
    // this.filters.to_date = moment(filters.to_date).format('YYYY-MM-DD');
    this.getAllFeesFilter(filters);
    /*if(this.user.user_type_id == 1){
  		this.getAllAllFees2(filters);
  	}else{
  	//	this.getCustomerPayments(this.user.id,filters);
  	}*/
  }
  submitFilters2(filters) {
    this.loading = true;
    this.filters.from_date = moment(
      this.domRange.getElementsByClassName("yk-content")[0].innerText,
      "MMM D, YYYY"
    ).format("YYYY-MM-DD");
    this.filters.to_date = moment(
      this.domRange.getElementsByClassName("yk-content")[1].innerText,
      "MMM D, YYYY"
    ).format("YYYY-MM-DD");
    this.paginationCurrentPage = 1;
    this.getAllFeesFilter(filters);
  }

  // search by type waived
  submitFilterWaived(waivved) {
    this.waived = waivved;
    // this.getAllAllFeesBywaived(waivved);
    this.filters.search = this.waived;
    // console.log('waived = ' + this.waived);
    this.submitFilters2(this.filters);
  }
  // search by type waived
  getAllAllFeesBywaived(waivved) {
    this.latefees = [];
    this.LS.getAllLateBywived(waivved).subscribe(
      (response) => {
        this.loading = false;

        if (response.body[0]) {
          this.latefees = response.body;
          this.totalPages = response.body[0].count;
        }
      },
      (error) => {
        console.log("Error obtaining late fees: ", error);
      }
    );
  }
  //obtener todo por medio de el filtro
  getAllFeesFilter(filters: any) {
    filters = { ...filters, page: this.paginationCurrentPage };
    this.latefees = [];
    // console.log('En getAllFeesFilter from = ' + filters.from_date);
    // console.log('En getAllFeesFilter limit = ' + filters.limit);
    // console.log('En getAllFeesFilter search = ' + filters.search);
    this.LS.getAllLateFees(filters).subscribe(
      (response) => {
        this.loading = false;
        if (response.body[0]) {
          this.latefees = response.body;
          this.totalPages = response.body[0].count;
          console.log(this.latefees);
        }
      },
      (error) => {
        console.log("Error obtaining late fees: ", error);
      }
    );
    this.filters.search = "";
  }
  // getAllLateFees(query : any){
  getAllLateFees() {
    this.LS.getAll().subscribe(
      (response) => {
        this.loading = false;
        this.latefees = response;
      },
      (error) => {
        console.log("Error obtaining late fees:", error);
      }
    );
  }

  openEditModal(latefee) {
    this.bsModalRef = this.modalService.show(
      EditLateFeeComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: true,
          ignoreBackdropClick: true,
          initialState: {
            latefee: { ...latefee },
          },
        }
      )
    );
    this.bsModalRef.content.latefee = latefee;
    // this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.editLateFeeModalClosed.subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }
  setDatasRow(latefee: string) {
    this.LS.setData(latefee);
  }

  reload(): void {
    this.search();
  }

  search() {
    this.loading = true;
    // this.getAllLateFees();
    this.filters.limit = this.paginationPageLimit;
    this.submitFilters(this.filters);
  }
  pageChanged(event: any) {
    this.paginationCurrentPage = event.page;
    if (this.loading === false) {
      this.search();
    }
  }
  closeSidebar() {
    window.scroll(0, 60);
    this.renderer.removeClass(document.body, "mini-navbar");
  }
  setSearchFilter(value) {
    this.filters.search = value.length ? value : "";
  }

  setLateFee() {
    this.LS.setLateFee(this.user.id).subscribe(
      (data) => {
        this.submitFilters(this.filters);
        this.showToast("Late fees set successfully", " Late Fees ", "success");
        this.getInfoUserLateFee();
      },
      (error) => {
        this.showToast(error.message.message, "Warning Late Fees", "warning");
        this.getInfoUserLateFee();
        console.log("Error setting late fees: ", error);
      }
    );
  }

  showToast(text: string, title, status): void {
    if (status == "error") {
      this.toastr.error(text, title, status);
    } else if (status == "warning") {
      this.toastr.warning(text, title, status);
    } else if (status == "info") {
      this.toastr.info(text, title, status);
    } else if (status == "success") {
      this.toastr.success(text, title, status);
    }
  }

  getInfoUserLateFee() {
    this.LS.getInfoUserLateFee().subscribe(
      (data) => {
        if (data) {
          this.infoLateFee = data;
        }
      },
      (error) => {
        console.log("Error setting late fees: ", error);
      }
    );
  }
}
