import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CryptoService,
  CustomerService,
  PaymentsService,
} from "src/app/_services";
import { User } from "src/app/_models";
import { FeeCalculator } from "../_helpers";
import * as globals from "../globals.json";
import * as moment from "moment";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MatDialog } from "@angular/material";
import { ReceiptModalComponent } from "../_common/send-receipt/send-receipt.component";

@Component({
  selector: "app-payment-receipt",
  templateUrl: "./payment-receipt.component.html",
  styleUrls: ["./payment-receipt.component.css"],
})
export class PaymentReceiptComponent implements OnInit {
  @ViewChild("printButton") printButton: ElementRef;
  @ViewChild("printGroupButton") printGroupButton: ElementRef;
  @ViewChild("inputNumber") private inputNumber: SwalComponent;

  public paymentId: string;
  public user: User;
  public payment: any;
  public globals: any = globals.default;
  public paymentCount = 0;
  public multiplePayments = false;
  public amountTotals = [];
  public grandTotal = 0;
  public feesTotal = 0;
  public IPayFeeMulti = 0;
  public TotalGross = 0;
  public ViewPaymentGroup = false;
  public IPayFee = 0;
  public USIOFee = 0;
  public totalAmountWithIPayFee = 0.0;
  public print_type = "page";

  public isIpad: boolean = false;

  constructor(
    private customerService: CustomerService,
    private paymentService: PaymentsService,
    public feeCalculator: FeeCalculator,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private dialog: MatDialog
  ) {}
  // a comment
  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.paymentId = this.route.snapshot.params.id;

    if (
      (this.isDeviceIPad() && this.user.user_type_id == 1) ||
      (this.isDeviceIPad() && this.user.user_type_id == 2)
    ) {
      this.isIpad = true;
    } else {
      this.isIpad = false;
    }

    if (this.user.user_type_id == 3) {
      //agregar estos para capturar el nomrbe del usuario cajero (this.user.user_info.first_name+" "+this.user.user_info.last_name)

      this.customerService
        .getCustomerPaymentById(this.user.id, this.paymentId)
        .subscribe(
          (data) => {
            this.payment = data;

            if (this.payment.source_type == "web") {
              // getIPayFeeDelta ONLY ACCOUNTS FOR WEB PAYMENTS
              switch (
                this.payment.pds_info.payment_type // data.amount IS THE FULL PAYMENT AMOUNT, FEES INCLUDED
              ) {
                case "ACH":
                  this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                    this.payment.pds_info.amount,
                    "ach"
                  )["fee"];
                  this.USIOFee = +this.feeCalculator.getUSIOFee(
                    this.payment.pds_info.amount,
                    "ach"
                  )["fee"];
                  this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                    this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                  );
                  break;
                // case "VISA": case "MSTR": case "DISC": case "AMEX":
                case "VISA" || "MSTR" || "DISC" || "AMEX":
                  this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                    this.payment.pds_info.amount,
                    "cc"
                  )["fee"];
                  this.USIOFee = +this.feeCalculator.getUSIOFee(
                    this.payment.pds_info.amount,
                    "cc"
                  )["fee"];
                  this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                    this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                  );
                  break;
                default:
                  this.totalAmountWithIPayFee = +data.amount;
                  break;
              }
            } else if (this.payment.source_type == "terminal") {
              if (this.payment.payment_source.includes("Front Desk")) {
                this.IPayFee = 0;
              } else {
                this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                  this.payment.pds_info.amount,
                  "terminal"
                )["fee"];
              }

              this.USIOFee = +this.feeCalculator.getUSIOFee(
                this.payment.pds_info.amount,
                "terminal"
              )["fee"];
              this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                this.payment.pds_info.amount + this.IPayFee + this.USIOFee
              );
            } else {
              this.totalAmountWithIPayFee = +data.amount; // IF NOT web, NO IPAY FEE
            }

            this.paymentService
              .getNumberOfPaymentsByTransactionId(
                this.payment.pds_info.confirmation_id
              )
              .subscribe(
                (data) => {
                  this.paymentCount = data.length;
                  if (this.paymentCount > 1) {
                    this.multiplePayments = true;
                    this.ViewPaymentGroup = true;
                    this.paymentService
                      .getPaymentsByTransactionId(
                        this.payment.pds_info.confirmation_id
                      )
                      .subscribe(
                        (data) => {
                          data.map((value, index) => {
                            this.amountTotals.push({
                              account_no: value.account.incode_account_no,
                              cashier: value.cashier_first_name || "N/A",
                              service_address:
                                value.service_address.street_address,
                              date_created: moment(value.date_created).format(
                                "MMMM D, YYYY h:mm a"
                              ),
                              payment_type: value.source_type,
                              total: parseFloat(value.pds_info.amount),
                              name:
                                value.user_info.first_name +
                                " " +
                                (value.user_info.last_name || ""),
                            });
                            this.feesTotal += parseFloat(value.pds_info.fees);
                            this.TotalGross += parseFloat(
                              value.pds_info.amount
                            );
                            this.grandTotal +=
                              parseFloat(value.pds_info.amount) +
                              parseFloat(value.pds_info.fees);
                            if (this.payment.source_type == "terminal") {
                              if (
                                this.payment.payment_source.includes(
                                  "Front Desk"
                                )
                              ) {
                                this.IPayFeeMulti = 0;
                              } else {
                                this.IPayFeeMulti =
                                  +this.feeCalculator.getIPayFeeDelta(
                                    this.TotalGross,
                                    "terminal"
                                  )["fee"];
                              }
                            }
                          });
                        },
                        (err) => {
                          if (err.status == 404) {
                            this.router.navigate(["404"]);
                          } else if (err.status === 403) {
                            this.router.navigate(["403"]);
                          }
                        }
                      );
                  }
                },
                (err) => {
                  if (err.status == 404) {
                    this.router.navigate(["404"]);
                  } else if (err.status === 403) {
                    this.router.navigate(["403"]);
                  }
                }
              );
          },
          (err) => {
            if (err.status == 404) {
              this.router.navigate(["404"]);
            } else if (err.status === 403) {
              this.router.navigate(["403"]);
            }
          }
        );
    } else {
      this.paymentService.getPaymentById(this.paymentId).subscribe(
        (data) => {
          this.payment = data;
          console.log(data);
          if (this.payment.source_type == "web") {
            // getIPayFeeDelta ONLY ACCOUNTS FOR WEB PAYMENTS
            switch (this.payment.pds_info.payment_type) {
              case "ACH":
                this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                  this.payment.pds_info.amount,
                  "ach"
                )["fee"];
                this.USIOFee = +this.feeCalculator.getUSIOFee(
                  this.payment.pds_info.amount,
                  "ach"
                )["fee"];
                this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                  this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                );
                break;
              // case "VISA": case "MSTR": case "DISC": case "AMEX":
              case "VISA" || "MSTR" || "DISC" || "AMEX":
                this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                  this.payment.pds_info.amount,
                  "cc"
                )["fee"];
                this.USIOFee = +this.feeCalculator.getUSIOFee(
                  this.payment.pds_info.amount,
                  "cc"
                )["fee"];
                this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
                  this.payment.pds_info.amount + this.IPayFee + this.USIOFee
                );
                break;
              default:
                this.totalAmountWithIPayFee = +data.amount;
                break;
            }
          } else if (this.payment.source_type == "terminal") {
            if (this.payment.payment_source.includes("Front Desk")) {
              this.IPayFee = 0;
            } else {
              this.IPayFee = +this.feeCalculator.getIPayFeeDelta(
                this.payment.pds_info.amount,
                "terminal"
              )["fee"];
            }

            this.USIOFee = +this.feeCalculator.getUSIOFee(
              this.payment.pds_info.amount,
              "terminal"
            )["fee"];
            this.totalAmountWithIPayFee = this.feeCalculator.moneyRound(
              this.payment.pds_info.amount + this.IPayFee + this.USIOFee
            );
          } else {
            this.totalAmountWithIPayFee = +data.amount; // IF NOT web, NO IPAY FEE
          }
          this.paymentService
            .getNumberOfPaymentsByTransactionId(
              this.payment.pds_info.confirmation_id
            )
            .subscribe(
              (data) => {
                this.paymentCount = data.length;

                if (this.paymentCount > 1) {
                  this.multiplePayments = true;
                  this.ViewPaymentGroup = true;
                  this.paymentService
                    .getPaymentsByTransactionId(
                      this.payment.pds_info.confirmation_id
                    )
                    .subscribe(
                      (data) => {
                        data.map((value, index) => {
                          this.amountTotals.push({
                            account_no: value.account.incode_account_no,
                            cashier: value.cashier_first_name || "N/A",
                            service_address:
                              value.service_address.street_address,
                            date_created: moment(value.date_created).format(
                              "MMMM D, YYYY h:mm a"
                            ),
                            payment_type: value.source_type,
                            total: parseFloat(value.pds_info.amount),
                            name:
                              value.user_info.first_name +
                              " " +
                              (value.user_info.last_name || ""),
                          });
                          this.feesTotal += parseFloat(value.pds_info.fees);
                          this.TotalGross += parseFloat(value.pds_info.amount);
                          this.grandTotal +=
                            parseFloat(value.pds_info.amount) +
                            parseFloat(value.pds_info.fees);
                          if (this.payment.source_type == "terminal") {
                            if (
                              this.payment.payment_source.includes("Front Desk")
                            ) {
                              this.IPayFeeMulti = 0;
                            } else {
                              this.IPayFeeMulti =
                                +this.feeCalculator.getIPayFeeDelta(
                                  this.TotalGross,
                                  "terminal"
                                )["fee"];
                            }
                          }
                        });
                      },
                      (err) => {
                        if (err.status == 404) {
                          this.router.navigate(["404"]);
                        } else if (err.status === 403) {
                          this.router.navigate(["403"]);
                        }
                      }
                    );
                }
              },
              (err) => {
                if (err.status == 404) {
                  this.router.navigate(["404"]);
                } else if (err.status === 403) {
                  this.router.navigate(["403"]);
                }
              }
            );
        },
        (err) => {
          if (err.status == 404) {
            this.router.navigate(["404"]);
          } else if (err.status === 403) {
            this.router.navigate(["403"]);
          }
        }
      );
    }
  }

  ngAfterViewInit() {
    this.paymentCount > 1
      ? this.printGroupButton.nativeElement.focus()
      : this.printButton.nativeElement.focus();
  }

  print(print_type) {
    this.print_type = print_type;

    // Without delay, classes don't have enough time to hide/show, will appear to show the opposite
    setTimeout(function () {
      window.print();
    }, 250);
  }

  openToGetNumber() {
    const dialogRef = this.dialog.open(ReceiptModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.phone) {
          this.sendSMS(result.phone);
        }
        if (result.email) {
          this.sendEmail(result.email);
        }
      }
    });
    // this.inputNumber.title = "Enter the number phone";
    // this.inputNumber.html =
    //   "<p> Enter the phone number to send SMS </p>" +
    //   '<div class="form-group"> ' +
    //   '<input id="number"  data-mask="(999) 999-9999" class="form-control text-center" type="text" placeholder="Phone Number"> ' +
    //   "</div>";
    // this.inputNumber.show();
  }

  sendEmail(email: any) {
    if (email == "") {
      Swal.fire({
        type: "info",
        title: "Oops...",
        text: "The field email cant empty, try again",
      });
    } else {
      if (this.ViewPaymentGroup) {
        this.paymentService
          .sendReceiptEmailMultiple(
            this.payment.pds_info.confirmation_id,
            email
          )
          .subscribe((data) => {
            Swal.fire({
              type: "info",
              title: "Success...",
              text: "Your Receipt email was sent successfully! You can send 1 more receipt copy via email if needed.",
            });
          });
      } else {
        this.payment.email_user = email;
        this.paymentService.sendReceiptEmail(this.payment).subscribe(
          (data) => {
            Swal.fire({
              type: "info",
              title: "Success...",
              text: "Your Receipt email was sent successfully! You can send 1 more receipt copy via email if needed.",
            });
          },
          (err) => {
            Swal.fire({
              type: "info",
              title: "Oops...",
              text: err.message,
            });
          }
        );
      }
    }
  }
  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
    //this.openToGetNumber()
  }

  cancel() {}

  sendSMS(num: any) {
    if (num == "") {
      Swal.fire({
        type: "info",
        title: "Oops...",
        text: "The field number cant empty, try again",
      });
    } else {
      let object = {
        paymentTo: this.globals.city,
        paymentFor: "Water Utilities",
        account: this.payment.account.incode_account_no,
        date:
          this.payment.date_created != ""
            ? moment(this.payment.date_created).format("MMMM D, YYYY")
            : "N/A",
        time:
          this.payment.date_created != ""
            ? moment(this.payment.date_created).format("H:mm a")
            : "N/A",
        transactionID:
          this.payment.pds_info.confirmation_id != ""
            ? this.payment.pds_info.confirmation_id
            : "N/A",
        paymentAmount:
          "$" +
          (this.payment.pds_info.amount != ""
            ? this.payment.pds_info.amount
            : "N/A"),
        adminFee: "$" + this.USIOFee,
        procesingFee: "$" + this.IPayFee,
        totalAmount: "$" + this.totalAmountWithIPayFee,
        number: num,
      };
      // if (this.ViewPaymentGroup) {
      //   this.paymentService
      //     .sendReceiptSMSMultiple(this.payment.pds_info.confirmation_id)
      //     .subscribe((data) => {
      //       Swal.fire({
      //         type: "info",
      //         title: "Success...",
      //         text: "Your SMS was sent successfully! You can send 1 more message if needed.",
      //       });
      //     });
      // } else {
      this.paymentService.sendReceiptSMS(object).subscribe(
        (data) => {
          Swal.fire({
            type: "info",
            title: "Success...",
            text: "Your SMS was sent successfully! You can send 1 more message if needed.",
          });
        },
        (err) => {
          Swal.fire({
            type: "info",
            title: "Oops...",
            text: err.message,
          });
        }
      );
      // }
    }
  }

  isDeviceIPad() {
    return (
      navigator &&
      navigator.userAgent &&
      navigator.maxTouchPoints &&
      navigator.platform &&
      ((navigator.userAgent.match(/Mac/) !== null &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints >= 1) || // iPad Pro (5 maxTouchPoints, use 2 to check)
        navigator.platform.match(/iPad/i) !== null)
    ); // iPad
  }

  viewPaymentGroup() {
    this.ViewPaymentGroup = !this.ViewPaymentGroup;
  }
}
