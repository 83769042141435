import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-terms",
  templateUrl: "./send-paylink.component.html",
  styleUrls: ["./send-paylink.component.css"],
})
export class SendPaylinkComponent implements OnInit {
  contactForm: FormGroup;
  errorMsg: string;

  constructor(
    public dialogRef: MatDialogRef<SendPaylinkComponent>,
    private fb: FormBuilder
  ) {
    this.contactForm = this.fb.group({
      email: [
        "",
        [
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
          ),
        ],
      ],
      phonePrefix: ["+1"],
      phone: [null, [Validators.pattern("^[0-9]+$")]],
    });
  }

  ngOnInit() {}

  onSubmit(): void {
    if (this.contactForm.valid) {
      const phoneFilled =
        this.contactForm.value.phone && this.contactForm.value.phonePrefix;
      const emailFilled = this.contactForm.value.email;

      if (phoneFilled || emailFilled) {
        if (phoneFilled) {
          this.contactForm.value.phone =
            this.contactForm.value.phonePrefix + this.contactForm.value.phone;
          delete this.contactForm.value.phonePrefix;
        }

        this.dialogRef.close(this.contactForm.value);
      } else {
        this.errorMsg =
          "You must enter at least one phone number or email address";
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
