import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import decode from "jwt-decode";
import { environment } from "../../environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl: string = environment.serverUrl;
  keyDecript: string = environment.keyDecript;

  @Output() loginEndModalOpen: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient) {}

  login(email: string, password: string, rememberMe: boolean, browser) {
    const url = `${this.baseUrl}/api/login`;
    return this.http
      .post<any>(url, {
        email: email,
        password: password,
        remember_me: rememberMe,
        browser: browser,
      })
      .pipe(
        map((result) => {
          if (result.user.user_type_id == 2 || result.user.user_type_id == 1) {
            result.isStaffUser = true;
            return result;
          } else {
            if (result.paymentMethods) {
              return result;
            }

            localStorage.setItem("access_token", result.token);
            localStorage.setItem("current_user", JSON.stringify(result.user));
            return true;
          }
        })
      );
  }

  logout() {
    console.log("logout user");
    return this.http.get(`${this.baseUrl}/api/logout`).subscribe(
      (data) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("current_user");
      },
      (err) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("current_user");
      }
    );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem("access_token");

    if (!token) {
      return false;
    }

    let tokenPayload = decode(token, { complete: true });
    let dateNow = new Date();
    let isAuthenticated = true;
    if (tokenPayload.exp < Date.now() / 1000) {
      isAuthenticated = false;
    }

    return isAuthenticated;
  }

  public getTokenExpirationTime() {
    const token = localStorage.getItem("access_token");
    let tokenPayload = decode(token, { complete: true });
    return tokenPayload.exp;
  }

  openLoginModal(open: boolean) {
    this.loginEndModalOpen.emit(open);
  }

  refreshToken(): Observable<boolean> {
    console.log("refresh token");
    return this.http.post<any>(`${this.baseUrl}/api/login/refresh`, {}).pipe(
      map((result) => {
        localStorage.removeItem("access_token");
        localStorage.setItem("access_token", result.token);
        return true;
      })
    );
  }

  staffLoginAuthentication(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/login/staff/authentication`,
      data
    );
  }

  handleDecrypt(text: any, _iv: any) {
    const key = CryptoJS.enc.Hex.parse(this.keyDecript);
    const iv = CryptoJS.enc.Hex.parse(_iv);

    const ciphertextBytes = CryptoJS.enc.Base64.parse(text);

    const decryptedBytes = CryptoJS.AES.decrypt(
      { ciphertext: ciphertextBytes },
      key,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // Convertir los bytes descifrados a cadena UTF-8
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    // Verificar si el texto descifrado es válido UTF-8
    if (!decryptedText) {
      throw new Error("Decryption failed: Invalid UTF-8 data");
    }

    return decryptedText;
  }
}
