import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";

import { User, UserSettings, Account } from "../_models";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseUrl: string = environment.serverUrl;
  private reloadSource = new BehaviorSubject<boolean>(false);
  reload = this.reloadSource.asObservable();

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(`${this.baseUrl}/api/users`);
  }

  getFiltered(
    page,
    limit,
    search,
    user_type,
    account_type,
    account_status,
    account_zone,
    userActive
  ) {
    return this.http.get<any>(
      `${this.baseUrl}/api/users?page=${page}&limit=${limit}&user_type=${user_type}&account_type=${account_type}&account_status=${account_status}&zone=${account_zone}&active=${userActive}&search=${search}`
    );
  }

  getById(id: string) {
    return this.http.get<User>(`${this.baseUrl}/api/users/${id}`);
  }

  getSettingsById(id: string) {
    return this.http.get<UserSettings>(
      `${this.baseUrl}/api/users/${id}/settings`
    );
  }

  getInfoById(id: string) {
    return this.http.get<any>(`${this.baseUrl}/api/users/${id}/info`);
  }

  getIncodeInfoById(incodeAccountNo) {
    return this.http.get<any>(
      `${this.baseUrl}/api/users/incode/${incodeAccountNo}`
    );
  }

  getAccountsById(id: string) {
    return this.http.get<Account[]>(`${this.baseUrl}/api/users/${id}/accounts`);
  }

  getUsersByAccountType(id: number) {
    return this.http.get(`${this.baseUrl}/api/users/${id}/account_type`);
  }

  getAccountZones() {
    return this.http.get(`${this.baseUrl}/api/zones/`);
  }

  createUser(user) {
    return this.http.post(`${this.baseUrl}/api/users`, user);
  }

  createLoginForExistingUser(user_info) {
    return this.http.post(`${this.baseUrl}/api/signup/existing`, user_info);
  }

  updateUserById(id: string, user) {
    return this.http.put(`${this.baseUrl}/api/users/${id}`, user);
  }

  updateSettingsById(id: string, settings) {
    return this.http.put(`${this.baseUrl}/api/users/${id}/settings`, settings);
  }

  updateUserInfoById(id: string, userInfo) {
    return this.http.put(`${this.baseUrl}/api/users/${id}/info`, userInfo);
  }

  updateAdminUserInfoById(id: string, userInfo) {
    return this.http.put(
      `${this.baseUrl}/api/users/${id}/info/admin`,
      userInfo
    );
  }

  reloadUserDetails(value: boolean) {
    this.reloadSource.next(value);
  }

  importIncodeAccount(incodeAccountNo) {
    return this.http.post(`${this.baseUrl}/api/users/import_incode_account`, {
      incode_account_no: incodeAccountNo,
    });
  }

  getMapData(beginMonth, beginYear, endMonth, endYear) {
    return this.http.get<any>(
      `${this.baseUrl}/api/users/map?beginMonth=${beginMonth}&beginYear=${beginYear}&endMonth=${endMonth}&endYear=${endYear}`
    );
  }

  deleteUser(data: any) {
    let options = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: data,
    };
    return this.http.delete(
      `${this.baseUrl}/api/users/${data.user_to_delete_id}`,
      options
    );
  }

  sendOtp(data) {
    return this.http.post(`${this.baseUrl}/api/users/send-otp-save`, data);
  }

  updateEmail(data) {
    return this.http.post(`${this.baseUrl}/api/users/update-email`, data);
  }

  getCashiers() {
    return this.http.get(`${this.baseUrl}/api/users/cashiers/all`);
  }
}
