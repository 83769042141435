import { Injectable } from "@angular/core";
import { UsioConstants } from "../usio-constants";

@Injectable()
export class FeeCalculator {
  //VARIATION : delta tiered dynamic pricing (getRegularFee is for CC payments)
  getRegularFee(paymentAmount: number): Object {
    let fee = 0;
    let feeString = "";
    let calculatedTier = 0;
    if (
      paymentAmount >= 1 &&
      paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]
    ) {
      // fee = UsioConstants.PRICE_CEILING_WEB_FEE_FLAT;
      fee = this.moneyRound(
        UsioConstants.PRICE_CEILING_WEB_FEE_FLAT -
          paymentAmount * UsioConstants.FEE_DECIMAL_WEB
      );
      feeString = `$${fee}`;
      calculatedTier = 1;
    } else if (paymentAmount > UsioConstants.DELTA_THRESHOLD[0]) {
      fee = this.moneyRound(
        paymentAmount * UsioConstants.PRICE_CEILING_WEB_DECIMAL
      );
      feeString = `${fee}%`;
      calculatedTier = 2;
    }
    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }

  getACHFee(paymentAmount: number): Object {
    let fee = 0;
    let feeString = "";
    let calculatedTier = 0;
    if (
      paymentAmount >= 1 &&
      paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]
    ) {
      fee = UsioConstants.PRICE_CEILING_FLAT_ACH;
      feeString = `$${UsioConstants.PRICE_CEILING_FLAT_ACH}`;
      calculatedTier = 1;
    } else if (paymentAmount > UsioConstants.DELTA_THRESHOLD[0]) {
      fee = this.moneyRound(
        paymentAmount * UsioConstants.PRICE_CEILING_PERCENT_ACH_DECIMAL
      );
      feeString = `${UsioConstants.PRICE_CEILING_PERCENT_ACH}%`;
      calculatedTier = 2;
    }
    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }
  //Different Delta models : Flat Conv. Fee Amount, Variable % based, Flat Fee Delta % ,
  //getIPayFee
  //getIPayFeeDeltaVARWEBcc
  //getIPayFeeDeltaFLATWEBcc
  getIPayFee(): Object {
    // regular Aguasud method
    let fee = 1.25;
    let feeString = "$1.25";
    let calculatedTier = 0;
    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }

  getIPayFeeDelta(paymentAmount: number, paymentType: string): Object {
    // combination of DeltaCC and DeltaACH ipay functions
    let fee = 0;
    let feeString = "";
    let calculatedTier = 0;
    switch (paymentType) {
      case "cc":
        switch (
          true // NEEDS true OR COMPARISON STATEMENTS IN case WILL ERROR
        ) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = this.moneyRound(
              UsioConstants.PRICE_CEILING_WEB_FEE_FLAT -
                paymentAmount * UsioConstants.FEE_DECIMAL_WEB
            );
            feeString = `$${fee}`;
            calculatedTier = 1;
            break;
          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.PRICE_CEILING_WEB_DECIMAL -
                paymentAmount * UsioConstants.FEE_DECIMAL_WEB
            );
            feeString = `$${fee}`;
            calculatedTier = 2;
            break;
        }
        break;
      case "ach":
        switch (
          true // NEEDS true OR COMPARISON STATEMENTS IN case WILL ERROR
        ) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = this.moneyRound(
              UsioConstants.PRICE_CEILING_FLAT_ACH -
                UsioConstants.ACH_CONV_FEE_FLAT
            );
            feeString = `$${fee}`;
            calculatedTier = 1;
            break;
          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.PRICE_CEILING_PERCENT_ACH_DECIMAL -
                UsioConstants.ACH_CONV_FEE_FLAT
            );
            feeString = `$${fee}`;
            calculatedTier = 2;
            break;
        }
        break;
      case "terminal":
        switch (true) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = this.moneyRound(
              UsioConstants.PRICE_CEILING_TERMINAL_FEE_FLAT -
                paymentAmount * UsioConstants.FEE_DECIMAL_TERMINAL
            );
            feeString = `$${fee}`;
            calculatedTier = 1;
            break;

          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.PRICE_CEILING_TERMINAL_DECIMAL -
                paymentAmount * UsioConstants.FEE_DECIMAL_TERMINAL
            );
            feeString = `$${fee}`;
            calculatedTier = 2;
            break;
        }
    }

    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }

  getUSIOFee(paymentAmount: number, paymentType: string): Object {
    let fee = 0;
    let feeString = "";
    let calculatedTier = 0; // ALWAYS 0, UNUSED FOR USIO FEE
    switch (paymentType) {
      case "cc":
        switch (
          true // NEEDS true OR COMPARISON STATEMENTS IN case WILL ERROR
        ) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.FEE_DECIMAL_WEB
            );
            feeString = `$${fee}`;
            break;
          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.FEE_DECIMAL_WEB
            );
            feeString = `$${fee}`;
            break;
        }

        break;
      case "ach":
        switch (
          true // NEEDS true OR COMPARISON STATEMENTS IN case WILL ERROR
        ) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = UsioConstants.ACH_CONV_FEE_FLAT;
            feeString = `$${fee}`;
            break;
          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = UsioConstants.ACH_CONV_FEE_FLAT;
            feeString = `$${fee}`;
            break;
        }
        break;
      case "terminal":
        switch (
          true // NEEDS true OR COMPARISON STATEMENTS IN case WILL ERROR
        ) {
          case paymentAmount <= UsioConstants.DELTA_THRESHOLD[0]: // use first of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.FEE_DECIMAL_TERMINAL
            );
            feeString = `$${fee}`;
            break;
          case paymentAmount > UsioConstants.DELTA_THRESHOLD[0]: // use second of threshold prices
            fee = this.moneyRound(
              paymentAmount * UsioConstants.FEE_DECIMAL_TERMINAL
            );
            feeString = `$${fee}`;
            break;
        }
        break;
    }
    console.log({
      fee: fee,
      feeString: feeString,
      calculatedTier: calculatedTier,
    });
    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }

  getPercentFee(percent_number: number, paymentAmount: number): Object {
    let fee = (percent_number / 100) * paymentAmount;
    let feeString = percent_number.toString();
    let calculatedTier = 0;
    return { fee: fee, feeString: feeString, calculatedTier: calculatedTier };
  }

  moneyRound(value: number): number {
    // Uses EPSILON for accurate rounding
    return parseFloat(
      (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2)
    );
  }
}
