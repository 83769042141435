import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-request-id",
  templateUrl: "./request-id.component.html",
  styleUrls: ["./request-id.component.css"],
})
export class RequestIDComponent implements OnInit {
  public imageUrl: string | string[] = ""; // Puede ser string o array
  public imageUrls: string[] = []; // Array para almacenar múltiples imágenes

  constructor(
    public bsModalRef: BsModalRef,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    // Si imageUrl es un array, lo asignamos a imageUrls
    if (Array.isArray(this.imageUrl)) {
      this.imageUrls = this.imageUrl;
    }
  }

  // Método para recibir y manejar la información de la imagen o imágenes
  setRequestInfo(imageUrl: string | string[]) {
    this.imageUrl = imageUrl;
    // Si es un array de imágenes, lo asignamos a imageUrls
    if (Array.isArray(imageUrl)) {
      this.imageUrls = imageUrl;
    }
  }

  // Función para verificar si es una sola imagen (string)
  isSingleImage(): boolean {
    return typeof this.imageUrl === "string" && !Array.isArray(this.imageUrl);
  }
}
