import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CurrencyPipe } from "@angular/common";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService, CustomerService, UsioService } from "src/app/_services";
import { User, Account, UserSettings, UserInfo } from "src/app/_models";
import { TermsComponent } from "../_common/terms/terms.component";

import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { UsioConstants } from "../usio-constants";

import { FeesComponent } from "../_common/fees/fees.component";
import { FeeCalculator } from "../_helpers";
import * as globals from "../globals.json";
import { forEach } from "@angular/router/src/utils/collection";
import * as Bowser from "bowser";
import { MONTHS } from "../constants.util";

declare var $: any;

@Component({
  selector: "app-payment-usio",
  templateUrl: "./payment-usio.component.html",
  styleUrls: ["./payment-usio.component.css"],
})
export class PaymentUsioComponent implements OnInit {
  @ViewChild("staticTabs") staticTabs: TabsetComponent;
  @ViewChild("confirmPayment") private confirmPaymentSwal: SwalComponent;
  @ViewChild("paymentSettings") private paymentSettingsSwal: SwalComponent;
  @ViewChild("successAlert") private successAlert: SwalComponent;
  @ViewChild("errorAlert") private errorAlertSwal: SwalComponent;

  public titleTabAccount = "1. Account Balance";
  public titleTabPaymentMethod = "2. Payment Method";
  public titleTabConfirm = "3. Confirmation";

  public acceptedCards: any = {};
  public globals: any = globals.default;

  public account: Account;
  public allAcounts: Array<Account> = [];

  public accountId: Number;

  public ccForm: FormGroup;
  public achForm: FormGroup;

  public user: UserInfo;
  public authToken: String = String();
  public userSettings: UserSettings;

  public isDepositBill = false;
  public disableButton = false;
  public allDisableButton = [];
  public checkPay = [];
  public loading = false;
  public allowQuickpay = false;
  public usingQuickpay = false;
  public quickpayType: String = String();
  public paymentMethodName: String = String();
  public bsModalRef: BsModalRef;
  public currentTab: number;

  public cardType: String = String();
  public payType = "full";
  public allPayType = [];
  public messagesAlert = [];
  public payAmount = 0.0;
  public allPayAmount = [];
  public totalAmount = 0.0;
  public totalFees = 0.0;

  public errorMessage: String = String();
  public creditCardNumber = "";

  public payMethod = "cc";
  public achSource = "checking";
  public IPayFee = 0.0;
  public USIOFee = 0.0;

  public allPay: boolean = false;

  public accounts: String = "";
  public messages = [];
  public saveMethodSetting = false;
  public isSafari = false;

  public months: Array<object> = [];
  public pay_method_select_save;
  public paymentMethods = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private customerService: CustomerService,
    private router: Router,
    private bsModalService: BsModalService,
    private fb: FormBuilder,
    private CP: CurrencyPipe,
    private usioService: UsioService,
    public feeCalculator: FeeCalculator,
    private cryptoService: CryptoService
  ) {
    this.acceptedCards = {
      visa: "VISA",
      mastercard: "MSTR",
      discover: "DISC",
      amex: "AMEX",
    };

    this.ccForm = this.clearCCForm();
    this.achForm = this.clearACHForm();
    this.months = MONTHS;
  }

  set ccNum(newCCNum: any) {
    this.ccForm.get("ccNum").setValue(newCCNum);
  }
  get ccNum() {
    return this.ccForm.get("ccNum");
  }
  get exp_month() {
    return this.ccForm.get("exp_month");
  }
  get exp_year() {
    return this.ccForm.get("exp_year");
  }
  get security_code() {
    return this.ccForm.get("security_code");
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.authToken = window.localStorage.access_token;
    this.accountId = this.activeRoute.snapshot.params.accountId;

    if (this.accountId.toString().includes("-")) {
      this.allPay = true;
    }

    this.staticTabs.tabs[0].active = true;
    this.staticTabs.tabs[1].disabled = true;
    this.staticTabs.tabs[2].disabled = true;
    this.getCurrentTab();

    let infoNav = Bowser.parse(window.navigator.userAgent);

    if (infoNav.browser.name == "Safari") {
      this.isSafari = true;
    }

    this.customerService
      .getCustomerSettingsById(this.user.id)
      .subscribe((data) => {
        this.saveMethodSetting = data["save_payment_method"];
        this.allowQuickpay = data["save_payment_method"];
        if (data["save_payment_method"]) {
          $("#save_payment_method_checkbox").click();
        }
        if (data["autopay"]) {
          $("#autopay_checkbox").trigger("click");
        }
      });

    this.getPaymentMethods();
  }

  cardKeyIsPressed(e) {
    if (e.shiftKey) {
      return e.which == 9;
    }
    if ((e.which > 57 && e.which < 95) || e.which > 105) {
      return false;
    }
    return e.which != 32;
  }

  formatCC() {
    let number = "";
    let displayNumber = "";
    // let formattedNumber;
    $("#ccNumber").val(function (index, value) {
      number = value;
      // formattedNumber = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
      if (
        number.length >= UsioConstants.CARD_NUMBER.MIN &&
        number.length <= UsioConstants.CARD_NUMBER.MAX
      ) {
        for (let i = 0; i < number.length - 4; i++) {
          displayNumber += "•";
        }
        displayNumber += number.substring(number.length - 4);
      }
      return displayNumber;
    });
    this.creditCardNumber = number;
  }

  cleanCCNumber() {
    const number = this.creditCardNumber;
    $("#ccNumber").val(function (index, value) {
      // return value.split(' ').join('');
      return number;
    });
  }

  toggleCheck(index) {
    this.checkPay[index] = this.checkPay[index] == true ? false : true;
  }

  ngAfterViewInit() {
    if (this.allPay) {
      this.customerService
        .getAllAccounts(this.user.id)
        .subscribe((response) => {
          this.allAcounts = response;

          if (!this.allAcounts) {
            //this.router.navigate(['/404']);
          }

          if (!this.activeRoute.snapshot.params.pdsInfoId) {
            // this.viewPayPanel();
          }

          this.setPayAmountArray();

          //FOR QUICKPAY
          //se aprueba los pagos que si son mayor a 1.01
          this.allAcounts.forEach((element) => {
            if (+element.balance >= 1.01) {
              this.checkPay.push(true);
            } else {
              this.checkPay.push(false);
            }

            this.customerService
              .checkIfUserCanUseQuickpay(this.user.id, element.id)
              .subscribe((response) => {
                this.allowQuickpay = response.allow_quickpay;
                if (this.allowQuickpay) {
                  return;
                }
              });
          });
        });

      this.customerService
        .getCustomerSettingsById(this.user.id)
        .subscribe((data) => {
          this.userSettings = data;
        });

      this.customerService
        .getAllCustomerBillsByUserId(this.user.id)
        .subscribe((data) => {
          if (data.body.total == 1 && data.body.bills[0].is_deposit_fee) {
            this.isDepositBill = true;
          }
        });

      this.customerService.getLatestPDSInfo(this.user.id).subscribe((data) => {
        if (data.payment_type) {
          // setup quickpay for this payment type
          this.quickpayType = data.payment_type.toLowerCase();
          this.setupQuickpay(this.quickpayType); // CHANGES this.payMethod TO THAT OF LAST PAYMENT
        } else {
          this.allowQuickpay = false;
        } // disable quickpay
      });
    } else {
      this.customerService
        .getAllAccounts(this.user.id)
        .subscribe((response) => {
          this.account = response.filter((x) => x.id == this.accountId)[0];

          if (!this.account) {
            this.router.navigate(["/404"]);
          }

          if (!this.activeRoute.snapshot.params.pdsInfoId) {
          }
          this.setPayAmount();

          //FOR QUICKPAY

          this.customerService
            .checkIfUserCanUseQuickpay(this.user.id, this.account.id)
            .subscribe((response) => {
              this.allowQuickpay = response.allow_quickpay;
            });
        });

      this.customerService
        .getCustomerSettingsById(this.user.id)
        .subscribe((data) => {
          this.userSettings = data;
        });

      this.customerService
        .getAllCustomerBillsByUserId(this.user.id)
        .subscribe((data) => {
          if (data.body.total == 1 && data.body.bills[0].is_deposit_fee) {
            this.isDepositBill = true;
          }
        });

      this.customerService.getLatestPDSInfo(this.user.id).subscribe((data) => {
        if (data.payment_type) {
          // setup quickpay for this payment type
          this.quickpayType = data.payment_type.toLowerCase();
          this.setupQuickpay(this.quickpayType); // CHANGES this.payMethod TO THAT OF LAST PAYMENT
        } else {
          this.allowQuickpay = false;
        } // disable quickpay
      });
    }
  }

  prepareConfirmationPage() {
    if (this.usingQuickpay === true) this.setupQuickpay(this.quickpayType); // NEEDED TO CALCULATE THE PROPER FEE
    switch (this.payMethod) {
      case "cc":
        this.USIOFee = this.feeCalculator.getUSIOFee(this.payAmount, "cc")[
          "fee"
        ];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(this.payAmount, "cc")[
          "fee"
        ];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +this.payAmount + +this.totalFees
        );
        break;
      case "ach":
        this.USIOFee = this.feeCalculator.getUSIOFee(this.payAmount, "ach")[
          "fee"
        ];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(
          this.payAmount,
          "ach"
        )["fee"];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +this.payAmount + +this.totalFees
        );
        break;

      case "save_payment_method":
        this.USIOFee = this.feeCalculator.getUSIOFee(this.payAmount, "cc")[
          "fee"
        ];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(this.payAmount, "cc")[
          "fee"
        ];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +this.payAmount + +this.totalFees
        );
        break;
    }
  }

  prepareConfirmationPageArray() {
    if (this.usingQuickpay === true) this.setupQuickpay(this.quickpayType); // NEEDED TO CALCULATE THE PROPER FEE

    let sum = 0;
    let accounts = "";
    let aux = 0;
    let account = "";

    for (let index = 0; index < this.allPayAmount.length; index++) {
      if (+this.allPayAmount[index] <= 1) {
      } else {
        if (this.checkPay[index]) {
          aux++;
          sum += +this.allPayAmount[index];
          account = this.allAcounts[index].incode_account_no;

          if (this.allAcounts.length == index + 1) {
            this.accounts =
              accounts + " | " + this.allAcounts[index].incode_account_no;
          } else {
            accounts += this.allAcounts[index].incode_account_no + " | ";
          }
        }
      }
    }

    this.accounts = accounts;

    switch (this.payMethod) {
      case "cc":
        this.USIOFee = this.feeCalculator.getUSIOFee(sum, "cc")["fee"];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(sum, "cc")["fee"];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +sum + +this.totalFees
        );
        this.payAmount = sum;
        break;
      case "ach":
        this.USIOFee = this.feeCalculator.getUSIOFee(sum, "ach")["fee"];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(sum, "ach")["fee"];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +sum + +this.totalFees
        );
        this.payAmount = sum;
        break;
      case "save_payment_method":
        this.USIOFee = this.feeCalculator.getUSIOFee(sum, "cc")["fee"];
        this.IPayFee = this.feeCalculator.getIPayFeeDelta(sum, "cc")["fee"];
        this.totalFees = this.feeCalculator.moneyRound(
          this.IPayFee + this.USIOFee
        );
        this.totalAmount = this.feeCalculator.moneyRound(
          +sum + +this.totalFees
        );
        this.payAmount = sum;
        break;
    }
  }

  paymentConfirmation() {
    this.errorMessage = "";

    if (this.payMethod === "save_payment_method") {
      if (this.allPay) {
        this.prepareConfirmationPageArray();

        this.loading = true;

        let paymentsMulti = [];

        this.allAcounts.forEach((element, i) => {
          if (this.checkPay[i]) {
            const paymentData = {
              amount: +this.allPayAmount[i],
              total_fees: +this.feeCalculator
                .getUSIOFee(+this.allPayAmount[i], "cc")
                ["fee"].toFixed(2),
              account_id: element.id.toString(),
            };
            paymentsMulti.push(paymentData);
          }
        });

        const paymentData = {
          confirmation_id: this.pay_method_select_save.confirmation_id,
          confirmation_id_ipay: this.pay_method_select_save.confirmation_ipay,
          source_type: "web",
          payment_source: "Web Payment",
          payments: paymentsMulti,
        };

        this.customerService
          .makeQuickPaymentSaveMethodMultiple(paymentData)
          .subscribe(
            (response) => {
              this.loading = false;
              this.router.navigate(["/payments"]);
            },
            (error) => {
              this.loading = false;
              this.errorAlertSwal.text =
                "Something went wrong, please try again later";
              this.errorAlertSwal.show();
            }
          );
        return;
      }

      this.loading = true;

      const paymentData = {
        confirmation_id: this.pay_method_select_save.confirmation_id,
        confirmation_id_ipay: this.pay_method_select_save.confirmation_ipay,
        amount: this.payAmount,
        total_fees: this.totalFees.toFixed(2),
        account_id: this.accountId,
        source_type: "web",
        payment_source: "Web Payment",
        card_type: this.pay_method_select_save.type_card,
      };

      this.customerService.makeQuickPaymentSaveMethod(paymentData).subscribe(
        (response) => {
          this.loading = false;
          this.router.navigate(["/payments", response.id, "receipt"]);
        },
        (error) => {
          this.loading = false;
          this.errorAlertSwal.text =
            "Something went wrong, please try again later";
          this.errorAlertSwal.show();
        }
      );
      return;
    }

    this.cleanCCNumber();
    if (this.usingQuickpay) {
      if (this.isSafari) {
        $("#modalSimpleConfirm").modal("show");
        $("#textModalSimpleConfirm").text(
          `Confirm you want to make a payment of ${this.CP.transform(
            this.totalAmount
          )}`
        );
      } else {
        this.confirmPaymentSwal.text = `Confirm you want to make a payment of ${this.CP.transform(
          this.totalAmount
        )}`;
        this.confirmPaymentSwal.show();
      }
    } else if (this.payMethod == "cc") {
      const ccNumberInput = document.getElementById(
        "ccNumber"
      ) as HTMLInputElement;
      const cardNumber = ccNumberInput.value;

      const numberValidation = this.getCardType(cardNumber);

      this.cardType = numberValidation || "CC";

      // $("#ccNumber").validateCreditCard((result) => {
      //   this.cardType = result.card_type.name || "CC";
      // });

      if (this.isSafari) {
        $("#modalSimpleConfirm").modal("show");
        $("#textModalSimpleConfirm").text(
          `Confirm you want to make a payment of ${this.CP.transform(
            this.totalAmount
          )}`
        );
      } else {
        this.confirmPaymentSwal.text = `Confirm you want to make a payment of ${this.CP.transform(
          this.totalAmount
        )}`;
        this.confirmPaymentSwal.show();
      }
    } else if (this.payMethod == "ach") {
      if (this.isSafari) {
        $("#modalSimpleConfirm").modal("show");
        $("#textModalSimpleConfirm").text(
          `Confirm you want to make a payment of ${this.CP.transform(
            this.totalAmount
          )}`
        );
      } else {
        this.confirmPaymentSwal.text = `Confirm you want to make a payment of ${this.CP.transform(
          this.totalAmount
        )}`;
        this.confirmPaymentSwal.show();
      }
    }
    this.formatCC();
  }

  makeQuickPayment() {
    this.loading = true;
    const paymentData = {
      amount: this.payAmount,
      total_fee: this.USIOFee.toFixed(2), // ONLY SENDS USIO FEE, IPAY FEE calculated in back-end
      account_id: this.accountId,
      source_type: "web",
      payment_source: "Web Payment",
      payment_method: this.quickpayType.toLowerCase(), // 'cc' OR 'ach'
    };
    this.customerService.makeUSIOQuickPayment(paymentData).subscribe(
      (response) => {
        this.loading = false;
        this.router.navigate(["/payments", response.id, "receipt"]);
      },
      (error) => {
        this.loading = false;
        if (this.isSafari) {
          $("#modalSimpleError").modal("show");
          $("#textModalError").text(error.message);
        } else {
          this.errorAlertSwal.text = error.message;
          this.errorAlertSwal.show();
        }
      }
    );
  }

  makeQuickPaymentArray() {
    this.loading = true;
    let payments = [];

    this.allAcounts.forEach((element, i) => {
      if (this.checkPay[i]) {
        const paymentData = {
          //auth: this.authToken,
          amount: +this.allPayAmount[i],
          total_fee: +this.feeCalculator
            .getUSIOFee(+this.allPayAmount[i], "cc")
            ["fee"].toFixed(2), // ONLY SENDS USIO FEE, IPAY FEE calculated in back-end
          account_id: element.id,
          source_type: "web",
          payment_source: "Web Payment",
          payment_method: this.quickpayType.toLowerCase(), // 'cc' OR 'ach'
        };
        payments.push(paymentData);
      }
    });

    this.customerService.makeUSIOMultipleQuickPayment(payments).subscribe(
      (response) => {
        this.loading = false;
        this.router.navigate(["/payments", response.id, "receipt"]);
      },
      (error) => {
        this.loading = false;
        if (this.isSafari) {
          $("#modalSimpleError").modal("show");
          $("#textModalError").text(error.message);
        } else {
          this.errorAlertSwal.text = error.message;
          this.errorAlertSwal.show();
        }
      }
    );
  }

  makePaymentArray() {
    $("#modalSimpleConfirm").modal("hide");

    if (this.usingQuickpay === true) {
      this.makeQuickPaymentArray();
    } else {
      this.loading = true;

      let payments = [];

      this.allAcounts.forEach((element, i) => {
        if (this.checkPay[i]) {
          const paymentData = {
            amount: +this.allPayAmount[i],
            total_fees: +this.feeCalculator
              .getUSIOFee(+this.allPayAmount[i], "cc")
              ["fee"].toFixed(2),
            account_id: element.id.toString(),
            user_email: this.regulateStringLength(
              this.user.email,
              UsioConstants.EMAIL.MIN,
              UsioConstants.EMAIL.MAX
            ),
            user_id: this.user.id.toString(),
            code: 1,
          };
          payments.push(paymentData);
        }
      });

      const full_name = this.user.first_name.trim();
      const first_name = full_name.split(" ")[0];
      const last_name =
        full_name.indexOf(" ") == -1
          ? first_name
          : full_name.slice(full_name.indexOf(" "), full_name.length);
      const state =
        this.allAcounts[0].service_address.state
          .trim()
          .toLowerCase()
          .includes("texas") ||
        this.allAcounts[0].service_address.state.trim().toLowerCase() == "tx"
          ? "TX"
          : this.allAcounts[0].service_address.state
              .trim()
              .toUpperCase()
              .slice(0, 2);
      const paymentInfo = {
        // payment_type IS ONLY USED FOR payment_method='cc' IN BACKEND
        //account_id: this.account.id.toString(),
        payments: payments,
        //amount: this.regulateStringLength(this.payAmount.toString(), UsioConstants.AMOUNT.MIN, UsioConstants.AMOUNT.MAX),
        //total_fees: this.regulateStringLength(this.USIOFee.toFixed(2), UsioConstants.CONV_FEE.MIN, UsioConstants.CONV_FEE.MAX),
        payment_method: this.payMethod,
        ccNum: this.regulateStringLength(
          this.creditCardNumber,
          UsioConstants.CARD_NUMBER.MIN,
          UsioConstants.CARD_NUMBER.MAX
        ),
        payment_type:
          this.payMethod == "cc"
            ? this.regulateStringLength(
                this.cardType.toString(),
                UsioConstants.CARD_TYPE.MIN,
                UsioConstants.CARD_TYPE.MAX
              ) || "other"
            : "",
        exp_date: this.regulateStringLength(
          this.exp_month.value + "20" + this.exp_year.value,
          UsioConstants.EXP_DATE.MIN,
          UsioConstants.EXP_DATE.MAX
        ),
        routing_number: this.regulateStringLength(
          this.achForm.get("routingNumber").value,
          UsioConstants.ROUTING_NUMBER.MIN,
          UsioConstants.ROUTING_NUMBER.MAX
        ),
        account_number: this.regulateStringLength(
          this.achForm.get("accountNumber").value,
          UsioConstants.ACCOUNT_NUMBER.MIN,
          UsioConstants.ACCOUNT_NUMBER.MAX
        ),
        ach_source:
          this.achForm.get("routingNumber").value === "" &&
          this.achForm.get("accountNumber").value === ""
            ? ""
            : this.achSource,
        //user_id: this.user.id.toString(),
        user_first_name: this.regulateStringLength(
          first_name,
          UsioConstants.FIRST_NAME.MIN,
          UsioConstants.FIRST_NAME.MAX
        ),
        user_last_name: this.regulateStringLength(
          last_name,
          UsioConstants.LAST_NAME.MIN,
          UsioConstants.LAST_NAME.MAX
        ),
        user_email: this.regulateStringLength(
          this.user.email,
          UsioConstants.EMAIL.MIN,
          UsioConstants.EMAIL.MAX
        ),
        service_address: this.regulateStringLength(
          this.allAcounts[0].service_address.street_address,
          UsioConstants.ADDRESS.MIN,
          UsioConstants.ADDRESS.MAX
        ),
        city: this.regulateStringLength(
          this.allAcounts[0].service_address.city,
          UsioConstants.CITY.MIN,
          UsioConstants.CITY.MAX
        ),
        state: this.regulateStringLength(
          state,
          UsioConstants.STATE.MIN,
          UsioConstants.STATE.MAX
        ),
        zip_code: this.regulateStringLength(
          this.allAcounts[0].service_address.zip_code,
          UsioConstants.ZIP_CODE.MIN,
          UsioConstants.ZIP_CODE.MAX
        ),
        security_code: this.regulateStringLength(
          this.security_code.value || "",
          UsioConstants.SECURITY_CODE.MIN,
          UsioConstants.SECURITY_CODE.MAX
        ),
        //notes: this.regulateStringLength(this.account.incode_account_no ? this.account.incode_account_no : this.account.id.toString(), UsioConstants.NOTES_1.MIN, UsioConstants.NOTES_1.MAX),
        notes: this.regulateStringLength(
          "AllPays",
          UsioConstants.NOTES_1.MIN,
          UsioConstants.NOTES_1.MAX
        ),
        source_type: "web",
        payment_source: "Web Payment",
      };

      this.customerService
        .makeMultiplePaymentsVirtualTerminal(paymentInfo)
        .subscribe(
          (results) => {
            this.loading = false;
            if (
              $("#save_payment_method_checkbox").is(":checked") &&
              this.payMethod == "cc"
            ) {
              console.log(this.account);
              const paymentMethodData = {
                ccNum: this.creditCardNumber.toString(),
                exp_date: this.exp_month.value + "20" + this.exp_year.value,
                security_code: this.security_code.value.toString(),
                nickname: this.paymentMethodName,
                user_first_name: first_name,
                user_last_name: this.regulateStringLength(
                  last_name,
                  UsioConstants.LAST_NAME.MIN,
                  UsioConstants.LAST_NAME.MAX
                ),
                service_address:
                  this.allAcounts[0].service_address.street_address,
                city: this.allAcounts[0].service_address.city,
                state: state,
                zip_code:
                  this.allAcounts[0].service_address.zip_code.toString(),
                user_email: this.user.email,
                phone: "",
                type_card: this.getCardType(this.creditCardNumber.toString()),
                payment_method: "cc",
                user_id: this.user.id.toString(),
              };

              this.customerService
                .createSavePaymentMethod(paymentMethodData)
                .subscribe((response) => {});
            }
            this.router.navigate(["/payments"]);
          },
          (error) => {
            this.loading = false;
            if (this.isSafari) {
              $("#modalSimpleError").modal("show");
              $("#textModalError").text(error.message);
            } else {
              this.errorAlertSwal.text = error.message
                ? error.message.message
                : error.error.error;
              this.errorAlertSwal.show();
            }
          }
        );
    }
  }

  getCardType = (numberCard: any) => {
    var numberClean = numberCard.replace(/[^\d]/g, "");
    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(numberClean)) {
      return "VISA";
    } else if (/^5[1-5][0-9]{14}$/.test(numberClean)) {
      return "MSTR";
    } else if (/^3[47][0-9]{13}$/.test(numberClean)) {
      return "AMEX";
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(numberClean)) {
      return "DISC";
    } else {
      return "VISA";
    }
  };

  makePayment() {
    // ONLY SENDS USIO FEE, IPAY FEE calculated in back-end
    if (this.payMethod === "save_payment_method") {
      console.log("SAVE PAYMENT METHOD WAS CALLED");
      return;
    }

    $("#modalSimpleConfirm").modal("hide");
    if (this.usingQuickpay === true) {
      this.makeQuickPayment();
    } else {
      this.loading = true;
      const full_name = this.user.first_name.trim();
      const first_name = full_name.split(" ")[0];
      const last_name =
        full_name.indexOf(" ") == -1
          ? first_name
          : full_name.slice(full_name.indexOf(" "), full_name.length);
      const state =
        this.account.service_address.state
          .trim()
          .toLowerCase()
          .includes("texas") ||
        this.account.service_address.state.trim().toLowerCase() == "tx"
          ? "TX"
          : this.account.service_address.state.trim().toUpperCase().slice(0, 2);
      const paymentInfo = {
        // payment_type IS ONLY USED FOR payment_method='cc' IN BACKEND
        account_id: this.account.id.toString(),
        amount: this.regulateStringLength(
          this.payAmount.toString(),
          UsioConstants.AMOUNT.MIN,
          UsioConstants.AMOUNT.MAX
        ),
        total_fees: this.regulateStringLength(
          this.USIOFee.toFixed(2),
          UsioConstants.CONV_FEE.MIN,
          UsioConstants.CONV_FEE.MAX
        ),
        payment_method: this.payMethod,
        ccNum: this.regulateStringLength(
          this.creditCardNumber,
          UsioConstants.CARD_NUMBER.MIN,
          UsioConstants.CARD_NUMBER.MAX
        ),
        payment_type:
          this.payMethod == "cc"
            ? this.regulateStringLength(
                this.cardType.toString(),
                UsioConstants.CARD_TYPE.MIN,
                UsioConstants.CARD_TYPE.MAX
              ) || "other"
            : "",
        exp_date: this.regulateStringLength(
          this.exp_month.value + "20" + this.exp_year.value,
          UsioConstants.EXP_DATE.MIN,
          UsioConstants.EXP_DATE.MAX
        ),
        routing_number: this.regulateStringLength(
          this.achForm.get("routingNumber").value,
          UsioConstants.ROUTING_NUMBER.MIN,
          UsioConstants.ROUTING_NUMBER.MAX
        ),
        account_number: this.regulateStringLength(
          this.achForm.get("accountNumber").value,
          UsioConstants.ACCOUNT_NUMBER.MIN,
          UsioConstants.ACCOUNT_NUMBER.MAX
        ),
        ach_source:
          this.achForm.get("routingNumber").value === "" &&
          this.achForm.get("accountNumber").value === ""
            ? ""
            : this.achSource,
        user_id: this.user.id.toString(),
        user_first_name: this.regulateStringLength(
          first_name,
          UsioConstants.FIRST_NAME.MIN,
          UsioConstants.FIRST_NAME.MAX
        ),
        user_last_name: this.regulateStringLength(
          last_name,
          UsioConstants.LAST_NAME.MIN,
          UsioConstants.LAST_NAME.MAX
        ),
        user_email: this.regulateStringLength(
          this.user.email,
          UsioConstants.EMAIL.MIN,
          UsioConstants.EMAIL.MAX
        ),
        service_address: this.regulateStringLength(
          this.account.service_address.street_address,
          UsioConstants.ADDRESS.MIN,
          UsioConstants.ADDRESS.MAX
        ),
        city: this.regulateStringLength(
          this.account.service_address.city,
          UsioConstants.CITY.MIN,
          UsioConstants.CITY.MAX
        ),
        state: this.regulateStringLength(
          state,
          UsioConstants.STATE.MIN,
          UsioConstants.STATE.MAX
        ),
        zip_code: this.regulateStringLength(
          this.account.service_address.zip_code,
          UsioConstants.ZIP_CODE.MIN,
          UsioConstants.ZIP_CODE.MAX
        ),
        security_code: this.regulateStringLength(
          this.security_code.value || "",
          UsioConstants.SECURITY_CODE.MIN,
          UsioConstants.SECURITY_CODE.MAX
        ),
        notes: this.regulateStringLength(
          this.account.incode_account_no
            ? this.account.incode_account_no
            : this.account.id.toString(),
          UsioConstants.NOTES_1.MIN,
          UsioConstants.NOTES_1.MAX
        ),
        source_type: "web",
        payment_source: "Web Payment",
      };

      this.customerService
        .makeCustomerPaymentVirtualTerminal(paymentInfo)
        .subscribe(
          (results) => {
            this.loading = false;
            if (
              $("#save_payment_method_checkbox").is(":checked") &&
              this.payMethod == "cc"
            ) {
              const paymentMethodData = {
                ccNum: this.creditCardNumber.toString(),
                exp_date: this.exp_month.value + "20" + this.exp_year.value,
                security_code: this.security_code.value.toString(),
                nickname: this.paymentMethodName,
                user_first_name: first_name,
                user_last_name: this.regulateStringLength(
                  last_name,
                  UsioConstants.LAST_NAME.MIN,
                  UsioConstants.LAST_NAME.MAX
                ),
                service_address: this.account.service_address.street_address,
                city: this.account.service_address.city,
                state: state,
                zip_code: this.account.service_address.zip_code.toString(),
                user_email: this.user.email,
                phone: "",
                type_card: this.getCardType(this.creditCardNumber.toString()),
                payment_method: "cc",
                user_id: this.user.id.toString(),
              };

              this.customerService
                .createSavePaymentMethod(paymentMethodData)
                .subscribe((response) => {});
            }
            this.router.navigate(["/payments", results.id, "receipt"]);
          },
          (error) => {
            this.loading = false;
            if (this.isSafari) {
              $("#modalSimpleError").modal("show");
              if (
                error.message.includes('"Card Number" must be a credit card.')
              ) {
                $("#textModalError").text(
                  "Please try refreshing your browser and try again"
                );
              } else {
                $("#textModalError").text(error.message);
              }
            } else {
              this.errorAlertSwal.text = error.error
                ? error.error.error
                : error.message;
              this.errorAlertSwal.show();
            }
          }
        );
    }
  }

  setPaymentMethodName() {
    this.paymentMethodName = $("#paymentMethodName").val();
  }

  triggerTermsModal() {
    const tModalRef = (this.bsModalRef = this.bsModalService.show(
      TermsComponent,
      Object.assign(
        {},
        {
          class: "gray modal-lg inmodal",
          backdrop: true,
          ignoreBackdropClick: false,
        }
      )
    ));
  }

  getCurrentTab() {
    for (let i = 0; i < this.staticTabs.tabs.length; i++) {
      if (this.staticTabs.tabs[i].active === true) {
        this.currentTab = i;
      }
    }
  }

  next(index) {
    switch (index) {
      case 0:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = false;
        this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 1:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        this.staticTabs.tabs[2].disabled = false;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = false
        break;
      default:
        break;
    }
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index + 1].active =
      !this.staticTabs.tabs[index + 1].active;
    this.getCurrentTab();
  }

  previous(index) {
    switch (index) {
      case 1:
        this.staticTabs.tabs[0].disabled = false;
        this.staticTabs.tabs[1].disabled = true;
        this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = false;
        this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 3:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        this.staticTabs.tabs[2].disabled = false;
        // this.staticTabs.tabs[3].disabled = true
        break;
      default:
        break;
    }
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index - 1].active =
      !this.staticTabs.tabs[index - 1].active;
    this.getCurrentTab();
  }

  saveSettings() {
    $("#modalSettings").modal("hide");
    const user_settings = {
      save_payment_method: $("#save_payment_method_checkbox").is(":checked"),
      autopay: $("#autopay_checkbox").is(":checked"),
      email_notifications: this.userSettings.email_notifications || false,
    };

    this.customerService
      .updateCustomerSettings(this.user.id, user_settings)
      .subscribe(
        (data) => {
          this.userSettings = data[0];
          if (this.isSafari) {
            $("#modalSimpleSettings").modal("show");
            $("#textModalSimpleSettings").text(`Settings updated`);
          } else {
            this.successAlert.title = "Success!";
            this.successAlert.text = "Settings updated";
            this.successAlert.show();
          }
        },
        (err) => {
          if (this.isSafari) {
            $("#modalSimpleError").modal("show");
            $("#textModalError").text(err.message);
          } else {
            this.errorAlertSwal.text = err.message;
            this.errorAlertSwal.show();
          }
        }
      );
  }

  regulateStringLength(value: string, min_length: number, max_length: number) {
    if (!value || value === null || value === undefined) {
      // If ACH, cardType = '', acceptedCards[cardType] = undefined. Fails at (value.trim())
      return "";
    } else if (value.trim().length < min_length) {
      return "";
    } else if (
      value.trim().length <= max_length &&
      value.trim().length >= min_length
    ) {
      return value.trim();
    } else if (value.trim().length > max_length) {
      return value.trim().substring(0, max_length);
    }
  }

  setSettings() {
    if (this.isSafari) {
      $("#modalSettings").modal("show");
    } else {
      this.paymentSettingsSwal.show();
      $("#save_payment_method_checkbox").attr(
        "checked",
        this.userSettings.save_payment_method
      );
      $("#autopay_checkbox").attr("checked", this.userSettings.autopay);
    }
  }

  setPayAmount() {
    // THIS PART SETS THE PAY AMOUNT TO BE THE FULL ACCOUNT BALANCE, EVEN IF CUSTOM IS SELECTED
    if (this.payType === "full") this.payAmount = this.account.balance;

    if (this.payAmount <= +this.account.balance + 500.0 && this.payAmount > 1) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  setPayAmountArray() {
    this.allAcounts.forEach((element, index) => {
      this.allPayType.push("full");
      // THIS PART SETS THE PAY AMOUNT TO BE THE FULL ACCOUNT BALANCE, EVEN IF CUSTOM IS SELECTED
      if (this.allPayType[index] === "full")
        this.allPayAmount.push(element.balance);

      if (
        this.allPayAmount[index] <= +element.balance + 500.0 &&
        this.allPayAmount[index] > 1
      ) {
        this.disableButton = false;
        this.messagesAlert.push(false);
      } else {
        this.disableButton = true;
        this.messagesAlert.push(true);
      }
    });
  }

  setPayAmountArrayEdit(index) {
    // THIS PART SETS THE PAY AMOUNT TO BE THE FULL ACCOUNT BALANCE, EVEN IF CUSTOM IS SELECTED
    if (this.allPayType[index] === "full")
      this.allPayAmount[index] = this.allAcounts[index].balance;

    if (
      this.allPayAmount[index] <= +this.allAcounts[index].balance + 500.0 &&
      this.allPayAmount[index] > 1
    ) {
      this.disableButton = false;
      this.messagesAlert[index] = false;
    } else {
      this.disableButton = true;
      this.messagesAlert[index] = true;
    }
  }

  openFeesModal() {
    this.bsModalRef = this.bsModalService.show(
      FeesComponent,
      Object.assign(
        {},
        {
          initialState: { amount: this.payAmount },
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: false,
          ignoreBackdropClick: true,
        }
      )
    );
    // this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.payMethod = this.payMethod;
    this.bsModalRef.content.closeBtnName = "Close";
  }

  openFeesModalArray() {
    let sum = 0;
    for (let index = 0; index < this.allPayAmount.length; index++) {
      if (+this.allPayAmount[index] <= 1) {
      } else {
        if (this.checkPay[index]) {
          sum += +this.allPayAmount[index];
        }
      }
    }
    this.bsModalRef = this.bsModalService.show(
      FeesComponent,
      Object.assign(
        {},
        {
          initialState: { amount: sum },
          class: "gray modal-lg inmodal animated bounceInRight",
          backdrop: false,
          ignoreBackdropClick: true,
        }
      )
    );
    // this.bsModalRef.content.subscribeToSettings();
    this.bsModalRef.content.payMethod = this.payMethod;
    this.bsModalRef.content.closeBtnName = "Close";
  }

  changePayMethod(method) {
    // 'cc', 'ach'
    this.payMethod = method;
    switch (method) {
      case "cc":
        this.achForm = this.clearACHForm();
        break;
      case "ach":
        this.ccForm = this.clearCCForm();
        break;
      default:
        this.achForm = this.clearACHForm();
        this.ccForm = this.clearCCForm();
        break;
    }
  }

  changeAchSource(source) {
    this.achSource = source;
  }

  clearCCForm() {
    this.cardType = "";
    return this.fb.group({
      ccNum: [
        "",
        [
          Validators.minLength(UsioConstants.CARD_NUMBER.MIN),
          Validators.maxLength(UsioConstants.CARD_NUMBER.MAX),
          Validators.required,
        ],
      ],
      exp_month: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      exp_year: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      security_code: ["", [Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  clearACHForm() {
    return this.fb.group({
      routingNumber: [
        "",
        [Validators.minLength(9), Validators.maxLength(9), Validators.required],
      ],
      accountNumber: [
        "",
        [
          Validators.minLength(5),
          Validators.maxLength(17),
          Validators.required,
        ],
      ],
    });
  }

  disableNextButton() {
    switch (this.payMethod) {
      case "ach":
        return !this.achForm.valid;
      case "cc":
        return !this.ccForm.valid;
    }
  }

  setupQuickpay(payment_type) {
    switch (payment_type.toLowerCase()) {
      case "ach":
        this.payMethod = "ach";
        break;
      case "cc":
      case "visa":
      case "mstr":
      case "amex":
      case "disc":
        this.payMethod = "cc";
        break;
      default:
        this.allowQuickpay = false; // IN CASE LAST PAYMENT WAS PHYSICAL TERMINAL
        break;
    }
  }

  setMonth(month: any) {
    this.ccForm.patchValue({ exp_month: month.value });
  }

  getPaymentMethods() {
    this.customerService.getPaymentMethods(this.user.id).subscribe(
      (response) => {
        if (response.length > 0) {
          this.paymentMethods = response;
          this.pay_method_select_save = response[0];
          response[0].selected = true;
        } else {
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );
  }

  selectPaymentMethod(method) {
    this.pay_method_select_save = method;
    this.paymentMethods.forEach((m) => (m.selected = false));
    method.selected = true;
  }

  getCardImage(type: string): string {
    switch (type) {
      case "mastercard":
        return "assets/images/mastercard.jpg";
      case "visa":
        return "assets/images/visa.jpg";
      case "paypal":
        return "assets/images/paypal.jpg";
      default:
        return "assets/images/visa.jpg";
    }
  }

  toggleCheckPayment(checkbox) {
    switch (checkbox) {
      case "autoPaymentCheck":
        break;
      case "savePaymentCheck":
        break;
    }
  }

  setsaveMethodSetting(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    const newValue = checkbox.checked;
    this.saveMethodSetting = newValue;
    $("#save_payment_method_checkbox").click();
  }
}
