import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TypeService, CustomerService, TicketService } from "../../_services";
import { TicketTypes, Account } from "src/app/_models";
import states from "../states.json";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.css"],
})
export class CreateTicketComponent implements OnInit {
  @ViewChild("createSuccess") private createSuccessSwal: SwalComponent;
  public newTicketFormGroup: FormGroup;
  public errorMessage: string;
  public disconnectAccountId = 12;
  public accounts: Account[];
  public ticketTypes: TicketTypes[];
  public user: any;
  public loading: Boolean = false;
  public inputFiles: File[] = [];
  public filesToUpload: File[] = [];
  public viewFiles: any[] = [];
  public textAlert: string = "Ticket Submitted";
  public enterTitlePlaceholder: string = "Enter Title";
  public enterDescriptionPlaceholder: string = "Enter Description";
  public uploadImageText: string = "Upload Image";
  public states: any = states;
  // public filteredTypes: any[] = [11]; //Remove new account from list

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private typeService: TypeService,
    private cd: ChangeDetectorRef,
    private TS: TicketService,
    private router: Router
  ) {
    this.newTicketFormGroup = this.fb.group({
      title: ["", Validators.required],
      description: ["", Validators.required],
      ticket_type: ["", Validators.required],
      account: ["", Validators.required],
      mail_forwarding: new FormControl(""),
      city: new FormControl(""),
      zip_code: new FormControl(""),
      state: new FormControl(""),
    });
  }

  changeTicketType(type) {
    switch (type) {
      case "Garbage Extra":
        return "Basura Extra";
      case "NAWSC Garbage":
        return "Basura NAWSC";
      case "Leaks":
        return "Fugas";
      case "Sewer Back Up":
        return "Respaldo con Alcantarilla";
      case "No Brush Pick Up":
        return "Sin Recoleccion por Escoba";
      case "No Garbage Pick Up":
        return "Sin Recoleccion de Basura";
      case "Low Pressure":
        return "Baja Presion";
      case "No Water":
        return "Sin agua";
      case "Payments":
        return "Pagos";
      case "General Questions":
        return "Preguntas Frecuentes";
      case "New Account":
        return "Nueva Cuenta";
      case "Disconnect Account":
        return "Desconectar Cuenta";
      case "Pause Account":
        return "Pausar Cuenta";
      case "Bad Sewer Smell":
        return "Mal olor de Alcantarilla";
      case "Manhole works":
        return "Trabajos de Alcantarilla";
      case "Mark locate water/sewer":
        return "Marcar Alcatarilla/Agua";
      case "Install Meter":
        return "Instalar Medidor";
    }
  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.current_user);
    this.states = states;
    this.customerService
      .getAllAccounts(this.user.id)
      .subscribe((data) => (this.accounts = data));

    this.typeService.getTicketTypes().subscribe((data) => {
      if (window.location.href.includes("/es/")) {
        data.map((item) => {
          item.name = this.changeTicketType(item.name);
          return item;
        });
      }
      this.ticketTypes = data;
    });

    this.newTicketFormGroup
      .get("ticket_type")
      .valueChanges.subscribe((value) => {
        if (value == this.disconnectAccountId) {
          this.newTicketFormGroup
            .get("mail_forwarding")
            .setValidators(Validators.required);
          this.newTicketFormGroup
            .get("city")
            .setValidators(Validators.required);
          this.newTicketFormGroup
            .get("zip_code")
            .setValidators(Validators.required);
          this.newTicketFormGroup
            .get("state")
            .setValidators(Validators.required);
        } else {
          this.newTicketFormGroup.get("mail_forwarding").clearValidators();
          this.newTicketFormGroup.get("city").clearValidators();
          this.newTicketFormGroup.get("zip_code").clearValidators();
          this.newTicketFormGroup.get("state").clearValidators();
        }
        this.newTicketFormGroup.get("mail_forwarding").updateValueAndValidity();
        this.newTicketFormGroup.get("city").updateValueAndValidity();
        this.newTicketFormGroup.get("zip_code").updateValueAndValidity();
        this.newTicketFormGroup.get("state").updateValueAndValidity();
      });

    if (window.location.href.includes("/es/")) {
      this.textAlert = "Ticket Enviado";
      this.enterTitlePlaceholder = "Ingrese un Título";
      this.enterDescriptionPlaceholder = "Ingrese una Descripción";
      this.uploadImageText = "Subir Imagen";
    }
  }

  test() {
    console.log(this.newTicketFormGroup.value);
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      this.inputFiles = <Array<File>>fileInput.target.files;
      //this.viewFiles = [];

      Array.from(this.inputFiles).forEach((file) => {
        this.filesToUpload.push(file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.viewFiles.push({ data: reader.result, name: file.name });
        };
      });
    }
  }

  submitNewTicket() {
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    this.loading = true;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i]["name"]);
      }
    }

    const details = {
      title: this.newTicketFormGroup.value.title,
      description: this.newTicketFormGroup.value.description,
      images: this.newTicketFormGroup.value.file,
      mail_forwarding: this.newTicketFormGroup.value.mail_forwarding,
      city: this.newTicketFormGroup.value.city,
      zip_code: this.newTicketFormGroup.value.zip_code,
      state: this.newTicketFormGroup.value.state,
    };

    formData.append(
      "ticket_type_id",
      this.newTicketFormGroup.value.ticket_type
    );
    formData.append("account_id", this.newTicketFormGroup.value.account);
    formData.append("details", JSON.stringify(details));

    this.customerService.createTicket(this.user.id, formData).subscribe(
      (data) => {
        this.loading = false;
        this.createSuccessSwal.show();
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  confirmNewTicket() {
    this.bsModalRef.hide();
    if (this.router.url == "/tickets") {
      this.TS.newTicketCreated();
    }
  }
}
