import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { TabsetComponent, BsModalService } from "ngx-bootstrap";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { CurrencyPipe } from "@angular/common";
import { CryptoService, CustomerService } from "../../_services";

import * as globals from "../../globals.json";
import data from "../states.json";
import { UsioConstants } from "../../usio-constants";
import { FeeCalculator } from "../../_helpers";
import { MONTHS } from "src/app/constants.util";

declare var $: any;

@Component({
  selector: "app-standalone-terminal",
  templateUrl: "./standalone-terminal.component.html",
  styleUrls: ["./standalone-terminal.component.css"],
})
export class StandaloneTerminalComponent implements OnInit {
  @ViewChild("staticTabs") staticTabs: TabsetComponent;
  @ViewChild("confirmPayment") private confirmPaymentSwal: SwalComponent;
  @ViewChild("successAlert") private successAlertSwal: SwalComponent;
  @ViewChild("errorAlert") private errorAlertSwal: SwalComponent;
  @ViewChild("paymentStatusModal")
  private paymentStatusTemplate: TemplateRef<any>;

  public paymentRef: BsModalRef;

  public currentTab: number;
  public globals: any = globals.default;
  public states: any = [];
  public creditCardNumber;
  public acceptedCards;
  public ccForm: FormGroup;
  public driverForm: FormGroup;
  public billingForm: FormGroup;
  public showTicketsSummary = true;
  public tickets = [];
  public isEdit = false;
  public editIndex;
  public total_fees: number;
  public total_amount: number;
  public pay_amount: number;
  public ticket_numbers: Array<string>;
  public card_type: string;
  public loading: boolean;
  public atLeastOneSuccessful: boolean;
  public paymentResults: Array<any>;
  public htmlMonths: string;
  public errorMessage: string;
  public IPAYFee: number;
  public totalFeesWithIPay: number;
  public finalAmount: number;
  public totalFees: number;
  public maxPaymentAmount: number;
  public amount: number;
  public logged_in_user;

  public titlePaymentInfo = "1. Payment Information";
  public titleConfirm = "2. Confirm";

  public months: Array<object> = [];

  constructor(
    private fb: FormBuilder,
    private feeCalculator: FeeCalculator,
    private CP: CurrencyPipe,
    private customerService: CustomerService,
    private modalService: BsModalService,
    private router: Router,
    private cryptoService: CryptoService
  ) {}

  get billingFirstName() {
    return this.billingForm.value["billingFirstName"];
  }
  get billingLastName() {
    return this.billingForm.value["billingLastName"];
  }
  get billingAddress1() {
    return this.billingForm.value["billingAddress1"];
  }
  get billingAddress2() {
    return this.billingForm.value["billingAddress2"];
  }
  get billingCity() {
    return this.billingForm.value["billingCity"];
  }
  get billingState() {
    return this.billingForm.value["billingState"];
  }
  get billingZip() {
    return this.billingForm.value["billingZip"];
  }
  get billingEmail() {
    return this.billingForm.value["billingEmail"];
  }
  get exp_month() {
    return this.ccForm.value["exp_month"];
  }
  get exp_year() {
    return this.ccForm.value["exp_year"];
  }
  get security_code() {
    return this.ccForm.value["security_code"];
  }
  get ticketAmount() {
    return this.ccForm.value["ticketAmount"];
  }
  get ticketNumber() {
    return this.ccForm.value["ticketNumber"];
  }

  ngOnInit() {
    this.staticTabs.tabs[0].active = true;
    this.staticTabs.tabs[1].disabled = true;
    this.getCurrentTab();
    this.total_fees = 0.0;
    this.total_amount = 0.0;
    this.ticket_numbers = [];
    this.pay_amount = 0.0;
    this.card_type = "";
    this.IPAYFee = 0.0;
    this.totalFees = 0.0;
    this.totalFeesWithIPay = 0.0;
    this.amount = 0.0;
    this.loading = false;
    this.paymentResults = [];
    this.tickets = [];
    this.atLeastOneSuccessful = false;
    this.creditCardNumber = "";
    this.states = data;
    this.logged_in_user = JSON.parse(window.localStorage.current_user);
    this.acceptedCards = {
      visa: "VISA",
      mastercard: "MSTR",
      discover: "DISC",
      amex: "AMEX",
    };

    this.htmlMonths =
      "Jan = 01  <br>" +
      "Feb = 02  <br>" +
      "Mar = 03  <br>" +
      "Apr = 04  <br>" +
      "May = 05  <br>" +
      "June = 06 <br>" +
      "July = 07 <br>" +
      "Aug = 08  <br>" +
      "Sept = 09 <br>" +
      "Oct = 10 <br>" +
      "Nov = 11 <br>" +
      "Dec = 12";
    if (window.location.href.includes("/es/")) {
      this.htmlMonths =
        "Ene = 01  <br>" +
        "Feb = 02  <br>" +
        "Mar = 03  <br>" +
        "Abr = 04  <br>" +
        "May = 05  <br>" +
        "Jun = 06 <br>" +
        "Jul = 07 <br>" +
        "Ago = 08  <br>" +
        "Sep = 09 <br>" +
        "Oct = 10 <br>" +
        "Nov = 11 <br>" +
        "Dic = 12";

      this.titlePaymentInfo = "1. Información del Pago";
      this.titleConfirm = "2. Confirmación";
    }

    this.resetBillingForm();
    this.resetCCForm();
    this.errorMessage = "";

    this.months = MONTHS;
  }

  prepareConfirmationPage() {
    this.IPAYFee = this.feeCalculator.getIPayFeeDelta(
      this.ticketAmount,
      "terminal"
    )["fee"];
    //this.total_fees = this.feeCalculator.getPercentFee(5, this.ticketAmount)['fee'];
    this.total_fees = this.feeCalculator.getUSIOFee(
      this.ticketAmount,
      "terminal"
    )["fee"];
    this.totalFees = this.total_fees;
    //this.total_amount = +this.ticketAmount + +this.total_fees;
    this.totalFeesWithIPay = this.feeCalculator.moneyRound(
      this.totalFees + this.IPAYFee
    );
    this.total_amount = this.ticketAmount; // = a totalAmount
    this.finalAmount = this.feeCalculator.moneyRound(
      this.total_amount + this.totalFeesWithIPay
    );

    this.maxPaymentAmount = this.amount + UsioConstants.PAY_LIMIT;

    console.log("this.IPAYFee ", this.IPAYFee);
    console.log("this.total_fees ", this.total_fees);
    console.log("this.totalFeesWithIPay ", this.totalFeesWithIPay);
    console.log("this.total_amount ", this.total_amount);
    console.log("this.finalAmount ", this.finalAmount);
    console.log("this.totalFees ", this.totalFees);
    console.log("this.maxPaymentAmount ", this.maxPaymentAmount);
  }

  paymentConfirmation() {
    this.cleanCCNumber();

    const ccNumberInput = document.getElementById(
      "ccNumber"
    ) as HTMLInputElement;
    const cardNumber = ccNumberInput.value;

    const numberValidation = this.getCardType(cardNumber);

    this.card_type = numberValidation || "CC";

    // $("#ccNumber").validateCreditCard((result) => {
    // this.card_type = result.card_type ? result.card_type.name : "CC";
    this.confirmPaymentSwal.text = `Confirm you want to make a payment of ${this.CP.transform(
      this.finalAmount
    )}`;
    if (window.location.href.includes("/es/")) {
      this.confirmPaymentSwal.text = `Confirme si quiere hacer el pago de ${this.CP.transform(
        this.finalAmount
      )}`;
    }
    this.confirmPaymentSwal.show();
    // });

    this.formatCC();
  }

  opentemplate() {
    this.paymentRef = this.modalService.show(
      this.paymentStatusTemplate,
      Object.assign({}, { class: "gray modal-md inmodal" })
    );
  }

  makePayment() {
    //  console.log('CONFIRM PAYMENT WAS CALLED')
    // console.log("pdskeys : ", this.pdsKeys);
    this.loading = true;

    let paymentInfo = {
      ccNum: this.regulateStringLength(
        this.creditCardNumber,
        UsioConstants.CARD_NUMBER.MIN,
        UsioConstants.CARD_NUMBER.MAX
      ),
      //lo mismo que decir payment_type
      payment_type: this.regulateStringLength(
        this.acceptedCards[this.card_type.toString()] || "other",
        UsioConstants.CARD_TYPE.MIN,
        UsioConstants.CARD_TYPE.MAX
      ),
      //lo mismo que amount
      amount: this.regulateStringLength(
        this.total_amount.toString(),
        UsioConstants.AMOUNT.MIN,
        UsioConstants.AMOUNT.MAX
      ),
      total_fees: this.regulateStringLength(
        this.totalFees.toFixed(2),
        UsioConstants.CONV_FEE.MIN,
        UsioConstants.CONV_FEE.MAX
      ),
      exp_date: this.regulateStringLength(
        this.exp_month + "20" + this.exp_year,
        UsioConstants.EXP_DATE.MIN,
        UsioConstants.EXP_DATE.MAX
      ),
      //lo mismo que user_first_name
      user_first_name: this.regulateStringLength(
        this.billingFirstName,
        UsioConstants.FIRST_NAME.MIN,
        UsioConstants.FIRST_NAME.MAX
      ),
      //lo mismo que user_last_name
      user_last_name: this.regulateStringLength(
        this.billingLastName,
        UsioConstants.LAST_NAME.MIN,
        UsioConstants.LAST_NAME.MAX
      ),
      //lo mismo que user_email
      user_email: this.regulateStringLength(
        this.billingEmail,
        UsioConstants.EMAIL.MIN,
        UsioConstants.EMAIL.MAX
      ),
      //lo mismo que service_address
      service_address: this.regulateStringLength(
        this.billingAddress1 + " " + this.billingAddress2,
        UsioConstants.ADDRESS.MIN,
        UsioConstants.ADDRESS.MAX
      ),
      city: this.regulateStringLength(
        this.billingCity,
        UsioConstants.CITY.MIN,
        UsioConstants.CITY.MAX
      ),
      state: this.regulateStringLength(
        this.billingState,
        UsioConstants.STATE.MIN,
        UsioConstants.STATE.MAX
      ),
      zip_code: this.regulateStringLength(
        this.billingZip,
        UsioConstants.ZIP_CODE.MIN,
        UsioConstants.ZIP_CODE.MAX
      ),
      security_code: this.regulateStringLength(
        this.security_code.value || "",
        UsioConstants.SECURITY_CODE.MIN,
        UsioConstants.SECURITY_CODE.MAX
      ),
      notes: this.regulateStringLength(
        this.ticketNumber,
        UsioConstants.NOTES_1.MIN,
        UsioConstants.NOTES_1.MAX
      ),
      cashier_id: this.logged_in_user.id,
      //payments : [],
      //terminal_id: this.regulateStringLength('', UsioConstants.TERMINAL_ID.MIN, UsioConstants.TERMINAL_ID.MAX),
      code: 1,
      user_id: this.logged_in_user.id.toString(),
      payment_source: "Stand Alone",
      ach_source: "checking",
      source_type: "terminal",
      payment_method: "cc",
      routing_number: "",
    };
    console.log("PAYMENT INFO: ", paymentInfo);

    this.customerService
      .makeStandaloneVirtualTerminalPayment(paymentInfo)
      .subscribe(
        (results) => {
          console.log("MAKE PAYMENT RESULTS: ", results);
          this.loading = false;
          this.paymentResults = results;
          this.successAlertSwal.text =
            "A receipt has been sent to the email provided";
          if (window.location.href.includes("/es/")) {
            this.successAlertSwal.text =
              "Se ha enviado un recibo al correo electrónico proporcionado";
          }
          this.successAlertSwal.show();
        },
        (error) => {
          console.log("MAKE PAYMENT ERROR: ", error);
          this.loading = false;
          this.cleanCCNumber();
          this.errorAlertSwal.text = error.message;
          this.errorAlertSwal.show();
        }
      );
  }

  restartProcess() {
    // this.ngOnInit();
    // this.previous(1);
    // this.cleanCCNumber();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/standalone/terminal"]);
  }

  // resetDriverForm() {
  //   this.driverForm = this.fb.group({
  //     driverFirstName: ['',[Validators.pattern('[a-zA-z ]*'), Validators.required]],
  //     driverLastName: ['', [Validators.pattern('[a-zA-z ]*'), Validators.required]],
  //     licenseNumber: ['', Validators.required],
  //     licenseState: ['TX', Validators.required],
  //     ticketNumber: ['', Validators.required],
  //     ticketAmount: ['0.00', [Validators.min(1), Validators.required]]
  //   });
  // }

  resetBillingForm() {
    this.billingForm = this.fb.group({
      billingFirstName: [
        "",
        [
          Validators.pattern("[a-zA-z ]*"),
          Validators.minLength(UsioConstants.FIRST_NAME.MIN),
          Validators.maxLength(UsioConstants.FIRST_NAME.MAX),
          Validators.required,
        ],
      ],
      billingLastName: [
        "",
        [
          Validators.pattern("[a-zA-z ]*"),
          Validators.minLength(UsioConstants.LAST_NAME.MIN),
          Validators.maxLength(UsioConstants.LAST_NAME.MAX),
          Validators.required,
        ],
      ],
      billingAddress1: [
        "",
        [
          Validators.minLength(UsioConstants.ADDRESS.MIN),
          Validators.maxLength(UsioConstants.ADDRESS.MAX),
        ],
      ],
      billingAddress2: [""],
      billingState: [
        "TX",
        [
          Validators.minLength(UsioConstants.STATE.MIN),
          Validators.maxLength(UsioConstants.STATE.MAX),
        ],
      ],
      billingCity: [
        "",
        [
          Validators.minLength(UsioConstants.CITY.MIN),
          Validators.maxLength(UsioConstants.CITY.MAX),
        ],
      ],
      billingZip: [
        "",
        [
          Validators.minLength(UsioConstants.ZIP_CODE.MIN),
          Validators.maxLength(UsioConstants.ZIP_CODE.MAX),
        ],
      ],
      billingEmail: [
        "",
        [
          Validators.email,
          Validators.minLength(UsioConstants.EMAIL.MIN),
          Validators.maxLength(UsioConstants.EMAIL.MAX),
          Validators.required,
        ],
      ],
      // billingPhone: ['', Validators.minLength(10)]
    });
  }

  resetCCForm() {
    this.ccForm = this.fb.group({
      ccNum: [
        "",
        [
          Validators.minLength(UsioConstants.CARD_NUMBER.MIN),
          Validators.maxLength(UsioConstants.CARD_NUMBER.MAX),
          Validators.required,
        ],
      ],
      exp_month: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      exp_year: [
        "",
        [Validators.minLength(2), Validators.maxLength(2), Validators.required],
      ],
      security_code: ["", [Validators.minLength(3), Validators.maxLength(4)]],
      ticketNumber: ["", Validators.required],
      ticketAmount: ["0.00", [Validators.min(1), Validators.required]],
    });
  }

  regulateStringLength(value: string, min_length: number, max_length: number) {
    if (value.trim().length < min_length) {
      return "";
    } else if (
      value.trim().length <= max_length &&
      value.trim().length >= min_length
    ) {
      return value.trim();
    } else if (value.trim().length > max_length) {
      return value.trim().substring(0, max_length);
    }
  }

  getCurrentTab() {
    // console.log(this.staticTabs.tabs)
    for (let i = 0; i < this.staticTabs.tabs.length; i++) {
      if (this.staticTabs.tabs[i].active === true) {
        this.currentTab = i;
      }
    }
  }

  next(index) {
    switch (index) {
      case 0:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = false;
        // this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 1:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        // this.staticTabs.tabs[2].disabled = false;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        // this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = false
        break;
      default:
        break;
    }
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index + 1].active =
      !this.staticTabs.tabs[index + 1].active;
    this.getCurrentTab();
  }

  previous(index) {
    switch (index) {
      case 1:
        this.staticTabs.tabs[0].disabled = false;
        this.staticTabs.tabs[1].disabled = true;
        // this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 2:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = false;
        // this.staticTabs.tabs[2].disabled = true;
        // this.staticTabs.tabs[3].disabled = true
        break;
      case 3:
        this.staticTabs.tabs[0].disabled = true;
        this.staticTabs.tabs[1].disabled = true;
        // this.staticTabs.tabs[2].disabled = false;
        // this.staticTabs.tabs[3].disabled = true
        break;
      default:
        break;
    }
    this.staticTabs.tabs[index].active = !this.staticTabs.tabs[index].active;
    this.staticTabs.tabs[index - 1].active =
      !this.staticTabs.tabs[index - 1].active;
    this.getCurrentTab();
  }

  cardKeyIsPressed(e) {
    if (e.shiftKey) {
      return e.which == 9;
    }
    if ((e.which > 57 && e.which < 95) || e.which > 105) {
      return false;
    }
    return e.which != 32;
  }

  amountKeyIsPressed(e) {
    console.log(e.which);
    if (e.shiftKey) {
      return e.which == 9;
    }
    if ((e.which > 57 && e.which < 95) || e.which > 105) {
      return false;
    }
    return e.which != 32;
  }

  formatCC() {
    let number = "";
    let displayNumber = "";
    // let formattedNumber;
    $("#ccNumber").val(function (index, value) {
      number = value;
      // formattedNumber = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
      if (
        number.length >= UsioConstants.CARD_NUMBER.MIN &&
        number.length <= UsioConstants.CARD_NUMBER.MAX
      ) {
        for (let i = 0; i < number.length - 4; i++) {
          displayNumber += "•";
        }
        displayNumber += number.substring(number.length - 4);
      }
      return displayNumber;
    });
    this.creditCardNumber = number;
  }

  cleanCCNumber() {
    const number = this.creditCardNumber;
    $("#ccNumber").val(function (index, value) {
      // return value.split(' ').join('');
      return number.length > 0 ? number : "";
    });
  }

  cancelEdit() {
    this.showTicketsSummary = true;
    this.isEdit = false;
    // this.resetDriverForm();
  }

  validateLicense(license: string) {
    const regex: RegExp =
      /[0-7][0-9]{2}[\W\s-][0-9]{2}[\W\s-][0-9]{4}|[\s\W][0-7][0-9]{8}[\s\W]/g;
    alert(regex.test(license));
  }

  onOpen(event) {
    $("body").removeClass("swal2-height-auto");
  }

  setMonth(month: any) {
    this.ccForm.patchValue({ exp_month: month.value });
    console.log(this.exp_month.value);
  }

  getCardType = (numberCard: any) => {
    var numberClean = numberCard.replace(/[^\d]/g, "");
    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(numberClean)) {
      return "VISA";
    } else if (/^5[1-5][0-9]{14}$/.test(numberClean)) {
      return "MSTR";
    } else if (/^3[47][0-9]{13}$/.test(numberClean)) {
      return "AMEX";
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(numberClean)) {
      return "DISC";
    } else {
      return "VISA";
    }
  };
}
