import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-terms",
  templateUrl: "./send-receipt.component.html",
  styleUrls: ["./send-receipt.component.css"],
})
export class ReceiptModalComponent implements OnInit {
  contactForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReceiptModalComponent>,
    private fb: FormBuilder
  ) {
    this.contactForm = this.fb.group({
      email: [
        "",
        [
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
          ),
        ],
      ],
      phonePrefix: ["+1"],
      phone: ["", [Validators.pattern("^[0-9]{10}$")]],
    }, { validator: this.atLeastOneFieldValidator });
  }

  ngOnInit() {
    this.contactForm.valueChanges.subscribe(() => {
      this.contactForm.updateValueAndValidity();
    });
  }

  atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } | null {
    const email = group.get('email');
    const phone = group.get('phone');
    const emailValue = email ? email.value : null;
    const phoneValue = phone ? phone.value : null;

    if (emailValue && emailValue.trim() !== "") {
      return null;
    }

    if (phoneValue && phoneValue.trim() !== "" && phoneValue.length === 10) {
      return null;
    }

    return { atLeastOneRequired: true };
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      const formValue = Object.assign({}, this.contactForm.value);
      if (formValue.phone) {
        formValue.phone = formValue.phonePrefix + formValue.phone;
        delete formValue.phonePrefix;
      }
      this.dialogRef.close(formValue);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}