import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import * as moment from "moment";

import { Account, Ticket, TicketComment } from "../_models";
import { UserSettings } from "src/app/_models/user-settings.model";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  baseUrl: string = environment.serverUrl;
  from: String;
  to: String;

  constructor(private http: HttpClient) {}
  getCustomersByZipCode(zipcode): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/zipcode/${zipcode}`
    );
  }

  getZipCodes(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.baseUrl}/api/customers/zip/codes`);
  }

  getAllBills(id, params): Observable<HttpResponse<any>> {
    this.from = moment(params.from_date).format("YYYY-MM-DD");
    this.to = moment(params.to_date).format("YYYY-MM-DD");

    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${id}/bills?page=${params.page}&limit=${
        params.limit
      }&from_date=${this.from}&to_date=${this.to}&status_id=${
        params.status_id
      }&search=${params.search ? params.search : ""}`,
      { observe: "response" }
    );
  }

  getAllCustomerBillsByUserId(user_id): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${user_id}/bills?`,
      { observe: "response" }
    );
  }

  getCustomerBillById(user_id: number, bill_id: number) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${user_id}/bills/${bill_id}`,
      { observe: "response" }
    );
  }

  getAllAccounts(user_id) {
    return this.http.get<Account[]>(
      `${this.baseUrl}/api/customers/${user_id}/accounts`
    );
  }

  checkIfUserCanUseQuickpay(user_id, account_id) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${user_id}/accounts/${account_id}/check_quickpay`
    );
  }

  getAllTickets(user_id) {
    return this.http.get<Ticket[]>(
      `${this.baseUrl}/api/customers/${user_id}/tickets`
    );
  }

  getBillsNoSearch(id) {
    return this.http.get(`${this.baseUrl}/api/customers/${id}/bills`);
  }

  signup(customer) {
    return this.http.post<any>(`${this.baseUrl}/api/signup/new`, customer);
  }

  uploadID(email, data) {
    return this.http.post<any>(`${this.baseUrl}/api/upload/id/${email}`, data);
  }

  uploadRA(email, data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/upload/property/${email}`,
      data
    );
  }

  updateCustomerInfo(id, userInfo) {
    return this.http.put<any>(
      `${this.baseUrl}/api/customers/${id}/info`,
      userInfo
    );
  }

  createCustomerWithAccount(customer) {
    return this.http.post(
      `${this.baseUrl}/api/customers/create_account`,
      customer
    );
  }

  getTicketById(user_id, ticket_id) {
    return this.http.get<Ticket>(
      `${this.baseUrl}/api/customers/${user_id}/tickets/${ticket_id}`
    );
  }

  getCustomerSettingsById(user_id) {
    return this.http.get<UserSettings>(
      `${this.baseUrl}/api/customers/${user_id}/settings`
    );
  }

  updateCustomerSettings(id, userSettings) {
    return this.http.put<any>(
      `${this.baseUrl}/api/customers/${id}/settings`,
      userSettings
    );
  }

  getFilteredTickets(user_id, page, limit, ticketStatus, ticketType, search) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${user_id}/tickets?page=${page}&limit=${limit}&ticket_status=${ticketStatus}&ticket_type=${ticketType}&search=${search}`
    );
  }

  createTicket(user_id, ticket) {
    return this.http.post(
      `${this.baseUrl}/api/customers/${user_id}/tickets`,
      ticket
    );
  }

  updateTicket(user_id, ticket_id, ticket) {
    return this.http.put(
      `${this.baseUrl}/api/customers/${user_id}/tickets/${ticket_id}`,
      ticket
    );
  }

  getCustomerPayments(id: number, q: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${id}/payments?search=${
        q.search || "undefined"
      }&from_date=${q.from_date}&to_date=${q.to_date}&status_id=${
        q.status_id
      }&limit=${q.limit}&page=${q.page}`,
      { observe: "response" }
    );
  }

  getCustomerPaymentById(user_id, payment_id) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${user_id}/payments/${payment_id}`
    );
  }

  getPDSDetails(pds_id) {
    return this.http.get(`${this.baseUrl}/api/payments/data/${pds_id}`);
  }

  makeCustomerPayment(payment) {
    return this.http.post<any>(`${this.baseUrl}/api/payments/pay`, payment);
  }

  makeUSIOMultipleQuickPayment(payments) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/quickpay/multiple`,
      payments
    );
  }

  makeCustomerPaymentPhysicalTerminal(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/terminal/physical`,
      payment
    );
  }

  makeMultiplePaymentsPhysicalTerminal(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/multiple_payments/terminal/physical`,
      data
    );
  }

  makeMultiplePaymentsVirtualTerminal(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/multiple_payments/terminal/virtual`,
      data
    );
  }

  makeCustomerPaymentVirtualTerminal(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/terminal/virtual`,
      payment
    );
  }

  makeStandaloneVirtualTerminalPayment(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/terminal/standalone`,
      payment
    );
  }

  getLatestPDSInfo(user_id) {
    return this.http.get<any>(
      `${this.baseUrl}/api/payments/latest/pds/${user_id}`
    );
  }

  makeQuickPayment(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/quickpay`,
      payment
    );
  }

  makeUSIOQuickPayment(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/quickpay`,
      payment
    );
  }

  getTicketComments(user_id, ticket_id) {
    return this.http.get<TicketComment[]>(
      `${this.baseUrl}/api/customers/${user_id}/tickets/${ticket_id}/comments`
    );
  }

  createTicketComment(user_id, ticket_id, comment) {
    return this.http.post(
      `${this.baseUrl}/api/customers/${user_id}/tickets/${ticket_id}/comments`,
      comment
    );
  }
  getDashboardInfo(id, aid) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${id}/dashboard/${aid}`
    );
  }

  getWaterConsumption() {
    return this.http.get<any>(`${this.baseUrl}/api/customers/water_con`);
  }

  resetPassword(email) {
    return this.http.post<any>(`${this.baseUrl}/api/reset_password`, email);
  }

  linkAdditionalAccount(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/customers/link_additional_account/`,
      data
    );
  }

  getRentalAgreement() {
    //need route to fetch
    return "https://dummyurl.com";
  }

  createSavePaymentMethod(data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/save-payment-method`,
      data
    );
  }

  getPaymentMethods(email) {
    return this.http.post<any>(
      `${this.baseUrl}/api/customers/payment_methods`,
      { user_id: email }
    );
  }
  deletePaymenMethods(id) {
    return this.http.delete<any>(
      `${this.baseUrl}/api/payments/delete-payment-method/${id}`
    );
  }

  getCronReminder(id) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/${id}/cron_reminder`
    );
  }

  setReminders(id, data) {
    return this.http.post<any>(
      `${this.baseUrl}/api/customers/${id}/set_reminders/`,
      data
    );
  }

  makeQuickPaymentSaveMethod(payment) {
    return this.http.post<any>(
      `${this.baseUrl}/api/payments/quickpay/with-method`,
      payment
    );
  }

  getAutopayCheckbox(id) {
    return this.http.get<any>(
      `${this.baseUrl}/api/customers/only/autopay/${id}`
    );
  }

  putAutopayCheckbox(id, autopay) {
    return this.http.put<any>(
      `${this.baseUrl}/api/customers/only/autopay/${id}`,
      { autopay: autopay }
    );
  }

  makeQuickPaymentSaveMethodMultiple(payments) {
    return this.http.post<any>(
      `${this.baseUrl}/api/multiple_payments/method-payment`,
      payments
    );
  }
}
