import { BrowserModule } from "@angular/platform-browser";
import { NgModule, enableProdMode } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NotificationSettings } from "../app/notification-settings/notification-settings.component";
import { NgxMaskModule } from "ngx-mask";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TabsModule } from "ngx-bootstrap/tabs";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { DataTableModule } from "angular-6-datatable";
import { DateRangePickerModule } from "ng-pick-daterange";
import { NgSelectModule } from "@ng-select/ng-select";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { PopoverModule } from "ngx-bootstrap/popover";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularDraggableModule } from "angular2-draggable";
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialog,
  MatDialogContent,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatInputModule,
  MatList,
  MatListModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
} from "@angular/material";
import { MatSliderModule } from "@angular/material/slider";

import { JwtInterceptor, ErrorInterceptor, FeeCalculator } from "./_helpers";
import { AuthService } from "./_services";
import { AuthGuard } from "./_guards";
import { environment } from "../environments/environment";
import { UpdateProfilePaymetMethod } from "./_common/update-payment-method/update-payment-method.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BasicLayoutComponent } from "./_common/basic-layout/basic-layout.component";
import { NavbarComponent } from "./_common/navbar/navbar.component";
import { SidebarComponent } from "./_common/sidebar/sidebar.component";
import { FooterComponent } from "./_common/footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UsersComponent } from "./users/users.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ForgotPasswordComponent } from "./forgot_password/forgot_password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { UserDetailsPanelComponent } from "./_common/user-details-panel/user-details-panel.component";
import { EditUserComponent } from "./_common/edit-user/edit-user.component";
import { FeesComponent } from "./_common/fees/fees.component";
import { CreateUserComponent } from "./_common/create-user/create-user.component";
import { CreateTicketComponent } from "./_common/create-ticket/create-ticket.component";
import { BillsComponent } from "./bills/bills.component";
import { BillDetailsComponent } from "./bill-details/bill-details.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { TicketDetailsComponent } from "./ticket-details/ticket-details.component";
import { DateDiffPipe } from "./_pipes/date-diff.pipe";
import { CustProfilePageComponent } from "./cust-profile-page/cust-profile-page.component";
import { RentalAgreementComponent } from "./_common/rental-agreement/rental-agreement.component";
import { UpdateProfileComponent } from "./_common/update-profile/update-profile.component";
import { LinkSentComponent } from "./_common/link-sent/link-sent.component";
import { CustomerSignupComponent } from "./customer-signup/customer-signup.component";
import { TermsComponent } from "./_common/terms/terms.component";
import { CreateCustomerComponent } from "./_common/create-customer/create-customer.component";
import { EditLateFeeComponent } from "./_common/edit-late-fee/edit-late-fee.component";

import { CommunicationComponent } from "./communication/communication.component";
import { EmailDetailComponent } from "./email-detail/email-detail.component";
import { EditTicketComponent } from "./_common/edit-ticket/edit-ticket.component";
import { PaymentsComponent } from "./payments/payments.component";
import { SignupRequestsComponent } from "./signup-requests/signup-requests.component";
import { ExistingUserComponent } from "./existing-user/existing-user.component";
import { WorkOrdersComponent } from "./work-orders/work-orders.component";
import { CreateWorkOrderComponent } from "./_common/create-work-order/create-work-order.component";
import { WorkOrderDetailsComponent } from "./work-order-details/work-order-details.component";
import { EditWorkOrderComponent } from "./_common/edit-work-order/edit-work-order.component";
import { RequestRAComponent } from "./_common/request-ra/request-ra.component";
import { RequestIDComponent } from "./_common/request-id/request-id.component";
import { FilterByIdPipe } from "./_pipes/filter-by-id.pipe";
import { PaymentReceiptComponent } from "./payment-receipt/payment-receipt.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { CreateGuestTicketComponent } from "./_common/create-guest-ticket/create-guest-ticket.component";
import { IncodeReviewComponent } from "./_common/incode-review/incode-review.component";
import { FormatPhonePipe } from "./_pipes/format-phone.pipe";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { MakePaymentComponent } from "./make-payment/make-payment.component";
import { LinkAdditionalAccountComponent } from "./_common/link-additional-account/link-additional-account.component";
import { FaqComponent } from "./faq/faq.component";
import { MapComponent } from "./map/map.component";
import { EsriMapComponent } from "./_common/esri-map/esri-map.component";
import { SyncPaymentsComponent } from "./_common/sync-payments/sync-payments.component";
import { PaymentReportComponent } from "./_common/payment-report/payment-report.component";
import { PaymentUsioComponent } from "./payment-usio/payment-usio.component";
import { CurrencyPipe } from "@angular/common";
import { VirtualTerminalComponent } from "./_common/virtual-terminal/virtual-terminal.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StaffLoginAuthenticationComponent } from "./staff-login-authentication/staff-login-authentication.component";
import { IncodeReportComponent } from "./incode-report/incode-report.component";
import { IncodeAdminFeeReportComponent } from "./incode-admin-fee-report/incode-admin-fee-report.component";
import { PolicyComponent } from "./policy/policy.component";
import { StandaloneTerminalComponent } from "./_common/standalone-terminal/standalone-terminal.component";
import { PrvImportComponent } from "./prv-import/prv-import.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { LateFeesComponent } from "./late-fees/late-fees.component";
import { LateFeeReportComponent } from "./_common/late-fee-report/late-fee-report.component";
import { TrafficPaymentsComponent } from "./traffic-payments/traffic-payments.component";
import { VirtualTerminalReportComponent } from "./_common/virtual-terminal-report/virtual-terminal-report.component";
import { TerminalReportComponent } from "./_common/terminal-report/terminal-report.component";
import { AdminReportComponent } from "./_common/admin-report/admin-report.component";
import { NgSwitcheryModule } from "angular-switchery-ios";
import { MetricsModuleComponent } from "./metrics-module/metrics-module.component";
import { ActivationsComponent } from "./usermetric/activations/activations.component";
import { UserGrowthComponent } from "./usermetric/user-growth/user-growth.component";
import { CountersPaysComponent } from "./usermetric/counters-pays/counters-pays.component";
import { LoginCountComponent } from "./usermetric/login-count/login-count.component";
import { ResetEmailTypeTimeComponent } from "./usermetric/reset-email-type-time/reset-email-type-time.component";
import { RestorePasswordComponent } from "./usermetric/restore-password/restore-password.component";
import { ErrorCodesBreakdownComponent } from "./error-metric/error-codes-breakdown/error-codes-breakdown.component";
import { TimeSeriesErrorCodeComponent } from "./error-metric/time-series-error-code/time-series-error-code.component";
import { ViewDownloadLogComponent } from "./usermetric/view-download-log/view-download-log.component";
import { VerifyUserComponent } from "./verify-user/verify-user.component";
import { MatBadgeModule, MatIconModule } from "@angular/material";

// import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from "ngx-toastr";
import { UserLinkedComponent } from "./_common/user-linked/user-linked.component";
import { PackagesComponent } from "./packages/packages.component";
import { ReversalsComponent } from "./reversals/reversals.component";
import { PaymentReportIvrComponent } from "./_common/payment-report-ivr/payment-report-ivr.component";
import { PaymentReportKioskComponent } from "./_common/payment-report-kiosk/payment-report-kiosk.component";
import { IncodeReportKioskComponent } from "./incode-report-kiosk/incode-report-kiosk.component";
import { SendPaylinkComponent } from "./_common/send-paylink/send-paylink.component";
import { ViewAditTrailComponent } from "./_common/view-adit-trail/view-adit-trail.component";
import { LocationModalComponent } from "./_common/location-modal/location-modal.component";
import { SecuryAuditTrailComponent } from "./secury-audit-trail/secury-audit-trail.component";
import { AgmCoreModule } from "@agm/core";
import { EmailAdminSettings } from "./_common/notification-emails-admin/notification-emails-admin.component";
import { ReceiptModalComponent } from './_common/send-receipt/send-receipt.component';

if (!environment.local || environment.production) {
  enableProdMode();

  window.console.log = function () { }; // disable any console.log debugging statements in production mode
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    BasicLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    PageNotFoundComponent,
    UsersComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserDetailsPanelComponent,
    EditUserComponent,
    FeesComponent,
    CreateUserComponent,
    CustProfilePageComponent,
    RentalAgreementComponent,
    UpdateProfileComponent,
    BillsComponent,
    BillDetailsComponent,
    CreateTicketComponent,
    TicketsComponent,
    PrvImportComponent,
    TicketDetailsComponent,
    DateDiffPipe,
    CommunicationComponent,
    EmailDetailComponent,
    EditTicketComponent,
    PaymentsComponent,
    SignupRequestsComponent,
    LinkSentComponent,
    CustomerSignupComponent,
    TermsComponent,
    ExistingUserComponent,
    WorkOrdersComponent,
    CreateWorkOrderComponent,
    WorkOrderDetailsComponent,
    EditWorkOrderComponent,
    RequestRAComponent,
    RequestIDComponent,
    FilterByIdPipe,
    PaymentReceiptComponent,
    LandingPageComponent,
    CreateGuestTicketComponent,
    IncodeReviewComponent,
    FormatPhonePipe,
    ForbiddenComponent,
    MakePaymentComponent,
    LinkAdditionalAccountComponent,
    FaqComponent,
    MapComponent,
    EsriMapComponent,
    SyncPaymentsComponent,
    PaymentReportComponent,
    PaymentUsioComponent,
    VirtualTerminalComponent,
    StaffLoginAuthenticationComponent,
    IncodeReportComponent,
    PolicyComponent,
    StandaloneTerminalComponent,
    CreateCustomerComponent,
    LateFeesComponent,
    EditLateFeeComponent,
    LateFeeReportComponent,
    TrafficPaymentsComponent,
    VirtualTerminalReportComponent,
    IncodeAdminFeeReportComponent,
    TerminalReportComponent,
    AdminReportComponent,
    MetricsModuleComponent,
    ActivationsComponent,
    UserGrowthComponent,
    CountersPaysComponent,
    LoginCountComponent,
    ResetEmailTypeTimeComponent,
    RestorePasswordComponent,
    ErrorCodesBreakdownComponent,
    TimeSeriesErrorCodeComponent,
    ViewDownloadLogComponent,
    VerifyUserComponent,
    UserLinkedComponent,
    PackagesComponent,
    ReversalsComponent,
    PaymentReportIvrComponent,
    PaymentReportKioskComponent,
    IncodeReportKioskComponent,
    NotificationSettings,
    UpdateProfilePaymetMethod,
    SendPaylinkComponent,
    ViewAditTrailComponent,
    LocationModalComponent,
    SecuryAuditTrailComponent,
    EmailAdminSettings,
    ReceiptModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableModule,
    NgSelectModule,
    DateRangePickerModule,
    NgxSpinnerModule,
    FlexLayoutModule,
    NgbModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    SweetAlert2Module.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    AngularDraggableModule,
    MatSliderModule,
    MatCardModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    NgSwitcheryModule,
    ToastrModule.forRoot(),
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatBadgeModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAKIpmQ-xnRT90I485tQKYcpPwdoZhRf2I",
      libraries: ["places"],
    }),
  ],
  exports: [],
  entryComponents: [
    EditUserComponent,
    UserLinkedComponent,
    FeesComponent,
    CreateUserComponent,
    CreateTicketComponent,
    CreateGuestTicketComponent,
    EditTicketComponent,
    RentalAgreementComponent,
    UpdateProfileComponent,
    LinkSentComponent,
    TermsComponent,
    CreateWorkOrderComponent,
    EditWorkOrderComponent,
    RequestRAComponent,
    RequestIDComponent,
    IncodeReviewComponent,
    LinkAdditionalAccountComponent,
    FaqComponent,
    PrvImportComponent,
    CreateCustomerComponent,
    LateFeesComponent,
    EditLateFeeComponent,
    NotificationSettings,
    UpdateProfilePaymetMethod,
    SendPaylinkComponent,
    ViewAditTrailComponent,
    LocationModalComponent,
    SecuryAuditTrailComponent,
    EmailAdminSettings,
    ReceiptModalComponent
  ],
  providers: [
    CurrencyPipe,
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    FeeCalculator,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
