import { Component, OnInit, ViewChild } from "@angular/core";
import { UserSettings } from "../_models";
import {
  UserService,
  CustomerService,
  CryptoService,
  BillsService,
} from "../_services";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { RentalAgreementComponent } from "../_common/rental-agreement/rental-agreement.component";
import { UpdateProfileComponent } from "../_common/update-profile/update-profile.component";
import { LinkAdditionalAccountComponent } from "../_common/link-additional-account/link-additional-account.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AccountService } from "../_services/account.service";
import * as moment from "moment";
import "rxjs/add/observable/forkJoin";
import * as globals from "../globals.json";
import { NotificationSettings } from "../notification-settings/notification-settings.component";
import { UpdateProfilePaymetMethod } from "../_common/update-payment-method/update-payment-method.component";
import { MatDrawer } from "@angular/material";
import { SwalComponent } from "@toverux/ngx-sweetalert2";

@Component({
  selector: "app-cust-profile-page",
  templateUrl: "./cust-profile-page.component.html",
  styleUrls: ["./cust-profile-page.component.css"],
})
export class CustProfilePageComponent implements OnInit {
  @ViewChild("successUpdate") private successUpdateSwal: SwalComponent;
  @ViewChild("failureUpdate") private failureUpdateSwal: SwalComponent;
  @ViewChild("editDataFailure") private editDataFailureSwal: SwalComponent;
  @ViewChild("enter2FA") private enter2FASwal: SwalComponent;
  @ViewChild("enterNewEmail") private enterNewEmailSwal: SwalComponent;
  @ViewChild("failureReset") private failureResetSwal: SwalComponent;
  @ViewChild("successUpdateEmail")
  private successUpdateEmailSwal: SwalComponent;
  public globals: any = globals.default;
  userSettings: UserSettings;
  autopay_checkbox;
  currentUser: any;
  userInfo: any;
  profileImg: string;
  mailingAddress: Observable<string>;
  serviceAddress: Observable<string>;
  accounts: any;
  payments: any;
  paymentDates: any;
  latestPayment: any;
  message: string;
  creationDateStatement: string;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  monthNamesSpanish = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  bsModalRef: BsModalRef;
  selectedAccount: any;
  profileDetailsLoading: boolean = false;
  paymentsMethods;
  accountData;
  cardListVisible: any = null;
  reminderCron = {
    billReminder: false,
    lateFeeReminder: false,
    lateNoticeReminder: false,
    disconnectWarning: false,
  };
  loading: boolean = false;
  switchbillReminder: any = null;
  switchlateFeeReminder: any = null;
  switchlateNoticeReminder: any = null;
  switchdisconnectWarning: any = null;

  textswitchbillReminder: string = "";
  textswitchlateFeeReminder: string = "";
  textswitchlateNoticeReminder: string = "";
  textswitchdisconnectWarning: string = "";

  successSetReminders: boolean = false;
  textSetReminders: string = "";
  errorSetReminders: boolean = false;
  profileStatusGroupForm = new FormGroup({});
  profileDetails = {
    profileImg: null,
    firstName: null,
    lastName: null,
    creationDateStatement: null,
    email: null,
    home_phone: null,
    mobile_phone: null,
    lastPayment: null,
    serviceAddress: null,
    mailingAddress: null,
    dob: null,
  };
  title: string;
  public successUpdateTitle: string =
    "Your profile settings have been updated!";
  public failureUpdateTitle: string =
    "Something went wrong in the process, we are very sorry";
  public selectAccount: string = "Select Account";
  public viewRentalText: string = "View Rental Agreement / Deed";
  public viewText: string = "View";
  public rentalAgreement: string = "Rental Agreement / Deed";
  panels: any[] = [];
  @ViewChild("drawer") drawer: MatDrawer;
  drawerMode: "over" | "side" = "side";
  drawerOpened: boolean = true;
  selectedPanel: string = "Profile";
  initialFormValues;
  validForm = false;
  trackByFn = (index) => index;
  constructor(
    private US: UserService,
    private CS: CustomerService,
    private AC: AccountService,
    private bsModalService: BsModalService,
    private fb: FormBuilder,
    private cryptoService: CryptoService,
    private BS: BillsService
  ) { }

  ngAfterView() { }

  ngOnInit() {
    this.profileDetailsLoading = true;
    this.getUser();
    this.getCronReminder();
    this.initForm();
    this.getAccounts(this.currentUser.id);
    this.getUserInfo(this.currentUser.id);
    this.getPayments(this.currentUser.id, {
      search: "",
      from_date: moment(this.currentUser.date_created).format("YYYY-MM-DD"),
      to_date: moment().format("YYYY-MM-DD"),
      status_id: 0,
      limit: 10,
      page: 1,
    });

    this.panels = [
      {
        id: "profile",
        icon: "account_circle",
        title: "My Profile",
        description: "Manage your public profile and private information",
        rol: 1,
      },
      {
        id: "linked-accounts",
        icon: "link",
        title: "Linked Accounts",
        description: " Link additional accounts to your profile",
        rol: 1,
      },
      {
        id: "payment-method",
        icon: "credit_card",
        title: "Payment Method",
        description: "Manage your payment methods",
        rol: 1,
      },
      {
        id: "configure-notifications",
        icon: "notifications",
        title: "Configure Notifications",
        description: "Manage when you'll be notified on which channels",
        rol: 1,
      },
    ];

    this.selectedPanel = "profile";
    if (window.location.href.includes("/es/")) {
      this.selectAccount = "Seleccionar Cuenta";
      this.viewRentalText = "Ver Contrato de Arrendamiento";
      this.viewText = "Ver";
      this.rentalAgreement = "Contrato de Arrendamiento";
    }
  }
  isFormChanged(): boolean {
    const currentFormValues = this.profileStatusGroupForm.value;
    return (
      JSON.stringify(currentFormValues) !==
      JSON.stringify(this.initialFormValues)
    );
  }

  initForm() {
    this.profileStatusGroupForm = this.fb.group({
      email: null,
      full_name: null,
      home_phone: null,
      mobile_phone: null,
      paymentMethod: null,
      dob: null,
      notificationRecieveCheck: null,
      autoPaymentCheck: null,
      savePaymentCheck: null,
      mailing_address: null,
      date_of_birth: null,
      service_address: null,
      incode_account_no: null,
    });
  }

  getUser() {
    this.currentUser = JSON.parse(window.localStorage.current_user);
    this.setUser();
  }

  translateMonth(month) { }

  getUserInfo(id) {
    this.US.getInfoById(id).subscribe((info) => {
      this.userInfo = info;
      this.creationDateStatement = `Joined on ${this.monthNames[new Date(this.userInfo.date_created).getMonth()]
        }, ${new Date(this.userInfo.date_created).getFullYear()}`;
      if (window.location.href.includes("/es/")) {
        //const formatter = new Intl.DateTimeFormat('es', { month: 'short' });
        this.creationDateStatement = `Suscrito en ${this.monthNamesSpanish[
          new Date(this.userInfo.date_created).getMonth()
        ]
          }, ${new Date(this.userInfo.date_created).getFullYear()}`;
      }

      this.setUserInfo();
    });
  }

  getAccounts(id) {
    this.CS.getAllAccounts(id).subscribe((accounts) => {
      this.accounts = accounts;
      this.profileDetailsLoading = false;
      this.selectedAccount = this.accounts[0];
      if (window.location.href.includes("/es/")) {
        switch (this.selectedAccount.account_type_name) {
          case "Residential": {
            this.selectedAccount.account_type_name = "Residencial";
            break;
          }
          case "Commercial": {
            this.selectedAccount.account_type_name = "Comercial";
            break;
          }
        }
      }
      this.setAccountInfo(this.accounts[0]);
    });
  }

  setAccountInfo(account) {
    this.profileDetails.serviceAddress = account.service_address;
    this.profileDetails.mailingAddress = account.mailing_address;
    this.getProfileUpdateData();
  }

  onSelectAccount() {
    setTimeout(() => { }, 1000);
  }

  getAccountByAccountId(id) {
    this.AC.getAccountById(id).subscribe((account) => { });
  }

  getPayments(id, q) {
    this.CS.getCustomerPayments(id, q).subscribe(
      (response) => {
        this.payments = response.body;
        this.getLatestPayment(this.payments);
        this.setLastPayment();
      },
      (error) => {
        console.log("Get customer paymetns error: ", error);
      }
    );
  }

  getLatestPayment(payments) {
    payments = payments.map((payment) => {
      var formatted;
      formatted = new Date(payment.date_updated).toLocaleDateString();
      return formatted;
    });
    this.latestPayment = payments[payments.length - 1];
  }

  getProfileUpdateInfo() {
    let objUserInfo = this.userInfo;
    let objCurrentUser = this.currentUser;
    var profileUpdateInfo = {};
    profileUpdateInfo = {
      ...objCurrentUser.email,
      ...objUserInfo.mobile_phone,
    };
    return profileUpdateInfo;
  }

  triggerRAModal(user) {
    const initialState = { message: "hi", title: "Rental Agreement" };
    const raModalRef = (this.bsModalRef = this.bsModalService.show(
      RentalAgreementComponent,
      Object.assign({}, { class: "gray modal-md inmodal", initialState })
    ));
    raModalRef.content.getRentalAgreement(this.selectedAccount);
  }

  triggerUpdateModal(user) {
    const uModalRef = (this.bsModalRef = this.bsModalService.show(
      UpdateProfileComponent,
      {
        ...{
          class: "gray modal-md inmodal animated bounceInRight",
          backdrop: "static",
        },
      }
    ));
    uModalRef.content.getParentUserInfo(this.userInfo);
    uModalRef.content.updatedProfile.subscribe((data) => {
      this.ngOnInit();
    });
  }

  triggerLinkAdditionalAccountModal(user) {
    this.bsModalRef = this.bsModalService.show(
      LinkAdditionalAccountComponent,
      Object.assign({}, { class: "gray inmodal animated slideDown" })
    );
    this.bsModalRef.content.refreshParentData.subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  setUser() {
    this.profileDetails.email = this.currentUser.email;
  }

  setUserInfo() {
    this.profileDetails.profileImg = this.userInfo.profile_image_url;
    this.profileDetails.firstName = this.userInfo.first_name;
    this.profileDetails.lastName = this.userInfo.last_name;
    this.profileDetails.creationDateStatement = this.creationDateStatement;
    this.profileDetails.home_phone = this.userInfo.home_phone;
    this.profileDetails.mobile_phone = this.userInfo.mobile_phone;
    this.profileDetails.lastPayment = this.latestPayment;
    this.profileDetails.dob = this.userInfo.date_of_birth;
  }

  setLastPayment() {
    this.profileDetails.lastPayment = this.latestPayment;
  }

  getCronReminder() {
    this.currentUser = JSON.parse(window.localStorage.current_user);
    const id = this.currentUser.id;

    this.CS.getCronReminder(id).subscribe((info) => {
      this.reminderCron.billReminder = info[0].cron_reminder["bill-reminder"];
      this.reminderCron.disconnectWarning =
        info[0].cron_reminder["disconnect-warning"];
      this.reminderCron.lateFeeReminder =
        info[0].cron_reminder["late-fee-warning"];
      this.reminderCron.lateNoticeReminder =
        info[0].cron_reminder["late-notice"];

      this.switchbillReminder = this.reminderCron.billReminder;
      this.switchlateFeeReminder = this.reminderCron.lateFeeReminder;
      this.switchlateNoticeReminder = this.reminderCron.lateNoticeReminder;
      this.switchdisconnectWarning = this.reminderCron.disconnectWarning;

      this.textswitchbillReminder = this.switchbillReminder ? "ON" : "OFF";
      this.textswitchlateFeeReminder = this.switchlateFeeReminder
        ? "ON"
        : "OFF";
      this.textswitchlateNoticeReminder = this.switchlateNoticeReminder
        ? "ON"
        : "OFF";
      this.textswitchdisconnectWarning = this.switchdisconnectWarning
        ? "ON"
        : "OFF";
    });
  }

  _switchbillReminder(event) {
    this.textswitchbillReminder = event ? "ON" : "OFF";
    this.reminderCron.billReminder = event;
  }

  _switchlateFeeReminder(event) {
    this.textswitchlateFeeReminder = event ? "ON" : "OFF";
    this.reminderCron.lateFeeReminder = event;
  }

  _switchlateNoticeReminder(event) {
    this.textswitchlateNoticeReminder = event ? "ON" : "OFF";
    this.reminderCron.lateNoticeReminder = event;
  }

  _switchdisconnectWarning(event) {
    this.textswitchdisconnectWarning = event ? "ON" : "OFF";
    this.reminderCron.disconnectWarning = event;
  }

  sendReminders() {
    this.currentUser = JSON.parse(window.localStorage.current_user);
    const id = this.currentUser.id;

    let data = {
      "bill-reminder": this.reminderCron.billReminder,
      "late-fee-warning": this.reminderCron.lateFeeReminder,
      "late-notice": this.reminderCron.lateNoticeReminder,
      "disconnect-warning": this.reminderCron.disconnectWarning,
    };

    this.CS.setReminders(id, data).subscribe(
      (info) => {
        this.textSetReminders = "Your changes were successfully saved";
        this.successSetReminders = true;
        this.errorSetReminders = true;
      },
      (error) => {
        this.textSetReminders = "Something went wrong, try again";
        this.successSetReminders = true;
        this.errorSetReminders = true;
      }
    );
  }

  closeReminders() {
    this.successSetReminders = false;
    this.errorSetReminders = false;
  }

  triggerNotificationSettings(user) {
    this.bsModalRef = this.bsModalService.show(NotificationSettings, {
      class: "modal-lg inmodal animated bounceInRight",
      backdrop: "static",
      initialState: {
        select: this.userInfo,
      },
    });
    this.bsModalRef.content.refreshParentData.subscribe((result) => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  triggerUpdatePaymentMethod(user) {
    const uModalRef = (this.bsModalRef = this.bsModalService.show(
      UpdateProfilePaymetMethod,
      {
        ...{
          class: "modal-lg inmodal animated bounceInRight",
          backdrop: "static",
          initialState: {
            select: this.selectedAccount,
          },
        },
      }
    ));
    uModalRef.content.getParentUserInfo(this.userInfo);
    uModalRef.content.updatedProfile.subscribe((data) => {
      this.ngOnInit();
    });
  }

  getPanelInfo(id: string): any {
    const titulo = this.panels.find((panel) => panel.id === id);
    this.title = titulo.title;
  }

  goToPanel(panel: string): void {
    this.selectedPanel = panel;

    if (this.selectedPanel === "linked-accounts") {
      this.triggerLinkAdditionalAccountModal(this.currentUser);
      this.selectedPanel = "profile";
      this.scrollToTop();
    }

    if (this.selectedPanel === "payment-method") {
      this.triggerUpdatePaymentMethod(this.currentUser);
      this.selectedPanel = "profile";
      this.scrollToTop();
    }

    if (this.selectedPanel === "configure-notifications") {
      this.triggerNotificationSettings(this.currentUser);
      this.selectedPanel = "profile";
      this.scrollToTop();
    }

    if (this.selectedPanel === "profile") {
      this.scrollToTop();
    }

    // Close the drawer on 'over' mode
    if (this.drawerMode === "over") {
      this.drawer.close();
    }
  }

  toggleCheck(checkbox) {
    switch (checkbox) {
      case "notificationRecieveCheck":
        this.profileStatusGroupForm.patchValue({
          notificationRecieveCheck:
            !this.profileStatusGroupForm.value.notificationRecieveCheck,
        });
        break;
      case "autoPaymentCheck":
        this.profileStatusGroupForm.patchValue({
          autoPaymentCheck: !this.profileStatusGroupForm.value.autoPaymentCheck,
        });

        if (this.profileStatusGroupForm.value.autoPaymentCheck == true) {
          this.autopay_checkbox = true;
          this.getPaymentMethod();
        } else {
          this.autopay_checkbox = false;
        }
        break;
      case "savePaymentCheck":
        this.profileStatusGroupForm.patchValue({
          savePaymentCheck: !this.profileStatusGroupForm.value.savePaymentCheck,
        });
        break;
    }
  }

  getPaymentMethod() {
    this.CS.getPaymentMethods(this.currentUser.id).subscribe(
      (response) => {
        if (response.length > 0) {
          this.autopay_checkbox = true;
          this.CS.getAllAccounts(this.currentUser.id).subscribe((res) => {
            this.accountData = res;
            this.paymentsMethods = response;

            this.accountData = this.combineData(this.paymentsMethods, res);
          });
        } else {
          this.autopay_checkbox = false;
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );
  }

  combineData(paymentMethods, accounts) {
    const combinedData = [];

    for (const account of accounts) {
      const paymentMethod = paymentMethods.find(
        (method) => method.id === parseInt(account.payment_method_id)
      );

      if (paymentMethod) {
        combinedData.push({
          ...account,
          paymentMethod,
        });
      } else {
        combinedData.push(account);
      }
    }

    return combinedData;
  }

  selectCard(account: any, method: any) {
    account.selectedPaymentMethod = method;

    this.AC.setPaymentMethod(account.id, method.id).subscribe((response) => { });
    this.cardListVisible = null;
    this.profileStatusGroupForm.patchValue({
      autoPaymentCheck: true
    })
    // this.getProfileUpdateData();
  }

  deselectCard(account) {
    account.selectedPaymentMethod = null;
    this.AC.setPaymentMethod(account.id, null).subscribe((response) => { });
    this.cardListVisible = null;
    this.getProfileUpdateData();
  }

  getProfileUpdateData() {
    this.US.getSettingsById(this.currentUser.id).subscribe((data) => {
      this.userSettings = data;
      var userDataObj = {
        ...this.userSettings,
        ...this.currentUser,
        ...this.userInfo,
        ...this.selectedAccount,
      };

      this.showProfileStatus(userDataObj);
    });
  }

  showProfileStatus(userData) {
    this.profileStatusGroupForm.patchValue({
      incode_account_no: userData.incode_account_no,
      service_address: userData.service_address.street_address,
      mailing_address: userData.mailing_address.street_address,
      email: userData.email,
      full_name:
        userData.first_name +
        " " +
        (userData.last_name && $.trim(userData).length > 0
          ? userData.last_name
          : ""),
      home_phone: userData.home_phone,
      mobile_phone: userData.mobile_phone,
      date_of_birth: userData.date_of_birth
        ? moment(userData.date_of_birth).format("MM/DD/YYYY")
        : "N/A",
      paymentMethod: "N/A",
      notificationRecieveCheck: userData.email_notifications,
      autoPaymentCheck: userData.autopay,
      savePaymentCheck: userData.save_payment_method,
    });
    (this.initialFormValues = this.profileStatusGroupForm.value),
      this.profileStatusGroupForm.statusChanges.subscribe((status) => {
        console.log("-->", userData)
        if (!this.profileStatusGroupForm.value.autoPaymentCheck) {
          this.autopay_checkbox = false;
        }
        if (this.profileStatusGroupForm.value.autoPaymentCheck) {

          this.autopay_checkbox = true;
          this.getPaymentMethod();
        }
        // Verificamos si hay algún cambio en los valores del formulario
        if (this.isFormChanged()) {
          if (this.profileStatusGroupForm.valid) {
            this.validForm = true;
          } else {
          }
        } else {
        }
      });

    this.CS.getPaymentMethods(this.currentUser.id).subscribe(
      (response) => {
        if (response.length > 0) {
          if (userData.autopay == true) {
            this.autopay_checkbox = true;
            this.CS.getAllAccounts(userData.user_id).subscribe((res) => {
              this.accountData = res;
              this.paymentsMethods = response;

              this.accountData = this.combineData(this.paymentsMethods, res);
            });
          }
        } else {
          this.autopay_checkbox = false;
        }
      },
      (error) => {
        console.log("Error getting Payment Methods", error);
      }
    );

    this.loading = false;
  }

  get savePaymentCheck() {
    return this.profileStatusGroupForm.value["savePaymentCheck"];
  }

  get notificationReceiveCheck() {
    return this.profileStatusGroupForm.value["notificationRecieveCheck"];
  }

  get paymentMethod() {
    return this.profileStatusGroupForm.value["paymentMethod"];
  }
  get email() {
    return this.profileStatusGroupForm.value["email"];
  }

  get home_phone() {
    return this.profileStatusGroupForm.value["home_phone"];
  }

  get mobile_phone() {
    return this.profileStatusGroupForm.value["mobile_phone"];
  }

  get autoPaymentCheck() {
    return this.profileStatusGroupForm.value["autoPaymentCheck"];
  }

  newPaymentMethod() {
    const uModalRef = (this.bsModalRef = this.bsModalService.show(
      UpdateProfilePaymetMethod,
      {
        class: "gray modal-lg inmodal animated bounceInRight",
        backdrop: "static",
      }
    ));

    // uModalRef.content.getParentUserInfo(this.userInfo);

    // uModalRef.content.updatedProfile.subscribe(() => {
    //   this.ngOnInit(); // Esto recarga el componente cuando se actualiza el perfil.
    // });

    // Suscribirse al evento onHidden a través de bsModalService para recargar al cerrar
    this.bsModalService.onHidden.subscribe(() => {
      this.ngOnInit(); // Recargar el componente cuando el modal se cierra
    });
  }



  toggleCardList(account: any) {
    if (this.cardListVisible === account) {
      this.cardListVisible = null;
    } else {
      this.cardListVisible = account;
    }
  }

  updateProfile() {
    // this.updateLoading = true;

    const userInfo = {
      mobile_phone: $("#mobile_phone")
        .val()
        .replace(/[()-\s]/g, ""),
      home_phone: $("#home_phone")
        .val()
        .replace(/[()-\s]/g, ""),
    };

    const customerSettings = {
      save_payment_method: this.savePaymentCheck,
      email_notifications: this.notificationReceiveCheck,
      autopay: this.autoPaymentCheck,
    };

    this.CS.updateCustomerInfo(this.currentUser.id, userInfo)
      .pipe(
        mergeMap((e) =>
          this.CS.updateCustomerSettings(this.currentUser.id, customerSettings)
        )
      )
      .subscribe(
        (data) => {
          if (data) {
            this.ngOnInit();
            this.message = "";
            this.successUpdateSwal.show();
          }
        },
        (err) => {
          this.message = "";
          this.failureUpdateSwal.show();
        }
      );
  }

  hideModal() { }

  editEmail() {
    // Obtener el nuevo correo que el usuario desea configurar
    const newEmail = this.profileStatusGroupForm.controls["email"].value;

    const data = {
      email: newEmail,
      user_id: this.currentUser.id,
    };

    // Enviar el 2FA al usuario
    this.BS.login2fa(data).subscribe((response) => {
      // Mostrar el diálogo de entrada del código 2FA
      this.enter2FASwal.show().then((result2) => {
        if (result2.value) {
          // Validar el código 2FA ingresado
          const dataValidate = {
            email: newEmail,
            token: result2.value,
          };

          this.BS.valide2fa(dataValidate).subscribe((response) => {
            if (response.body.user) {
              // Si la validación del 2FA es correcta, mostrar el modal para ingresar el nuevo correo
              this.enterNewEmailSwal.show().then((result3) => {
                if (result3.value) {
                  // Obtener el nuevo correo ingresado por el usuario
                  const updatedEmail = result3.value;

                  // Actualizar el email con el nuevo valor ingresado
                  const updateData = {
                    email_old: newEmail,
                    email: updatedEmail,
                    user_id: this.currentUser.id,
                  };

                  this.US.updateEmail(updateData).subscribe(
                    () => {
                      // actualizar el correo en el local storage
                      this.currentUser.email = updatedEmail;
                      localStorage.setItem(
                        "current_user",
                        JSON.stringify(this.currentUser)
                      );

                      // refresh the page to reflect the changes in the UI
                      this.ngOnInit();

                      this.successUpdateEmailSwal.show();
                    },
                    (error) => {
                      // Mostrar mensaje de error
                      this.failureUpdateTitle = "Something went wrong";
                      this.failureResetSwal.text = error.message.message
                        ? error.message.message
                        : "Something went wrong. Please try again.";

                      this.message = error.message.message
                        ? error.message.message
                        : "Something went wrong. Please try again.";

                      this.failureResetSwal.show();
                    }
                  );
                }
              });
            } else {
              // Si la validación del 2FA falla, mostrar mensaje de error
              this.failureUpdateTitle = "Invalid 2FA code";
              this.failureResetSwal.text =
                "Invalid 2FA code. Please try again.";
              this.failureResetSwal.show();
            }
          });
        }
      });
    });
  }

  sendOtp(type: string) {
    if (type == "phone") {
      if (
        this.profileStatusGroupForm.value.mobile_phone == null ||
        this.profileStatusGroupForm.value.mobile_phone == ""
      ) {
        this.editDataFailureSwal.title = "Please enter a valid phone number";
        this.editDataFailureSwal.show();
        return;
      }
    }

    this.US.sendOtp({ user_id: this.currentUser.id, type }).subscribe(
      (data: any) => {
        this.userSettings = data.data[0];
      },
      (error) => {
        this.editDataFailureSwal.title = error.message;
        this.editDataFailureSwal.show();
      }
    );
  }

  scrollToTop() {
    const element = document.querySelector(".perfil");

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
