import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { mergeMap } from "rxjs/operators";

import {
  UserService,
  AccountService,
  ForgotPasswordService,
  ZoneService,
  CustomerService,
  AddressService,
  CryptoService,
} from "../../_services";
import { User, UserSettings, Account, Zone } from "../../_models";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RequestIDComponent } from "src/app/_common/request-id/request-id.component";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit, OnChanges {
  @Output() editUserModalClosed = new EventEmitter();
  @ViewChild("editSuccess") private editSuccessSwal: SwalComponent;
  @ViewChild("editFailure") private editFailureSwal: SwalComponent;
  @ViewChild("editDataFailure") private editDataFailureSwal: SwalComponent;
  @ViewChild("success") private successSwal: SwalComponent;
  @ViewChild("failure") private failureSwal: SwalComponent;
  @ViewChild("400failure") private notFoundFailureSwal: SwalComponent;

  public sentMessage: string;
  public email: string;
  public loading: boolean = false;
  public userSettings: UserSettings;
  public user;
  public logged_in_user: User;
  public accounts: Account[];
  public selectedAccount: Account;
  public updateUserFormGroup: FormGroup;
  public adminEditUserFormGroup: FormGroup;
  public disableMailingAddress: boolean = false;
  public bsConfig: Partial<BsDatepickerConfig>;
  public datePickerDate: any;
  public initialBirthDate = moment().subtract(20, "years").format("MM/DD/YYYY");
  public submitSwalAlertTitle: string = "Save Changes?";
  public editSuccessAlertTitle: string = "User has been edited";
  public editFailureAlertTitle: string = "There was an error";
  public editFailureAlertText: string = "Please try again later";
  public successAlertTitle: string = "A link has been sent to you";
  public _400failureAlertTitle: string =
    "The e-mail you entered is invalid or was not found";
  public failureAlertTitle = "Something went wrong, we are very sorry";
  public selectAccountPlaceholder = "Select Account";
  public zones: Zone[] = [];
  public flag_use: boolean = false;

  constructor(
    private userService: UserService,
    private forgotPasswordService: ForgotPasswordService,
    public bsModalRef: BsModalRef,
    public bsModalService: BsModalService,
    private accountService: AccountService,
    private zoneService: ZoneService,
    private addressService: AddressService,
    private fb: FormBuilder,
    private CS: CustomerService,
    private cryptoService: CryptoService
  ) {
    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue" });
    this.updateUserFormGroup = this.fb.group({
      firstName: ["", Validators.required],
      lastName: [""],
      dateOfBirth: [""],
      driverLicenseNumber: [""],
      homePhone: [""],
      mobilePhone: [""],
      email: [""],
      emailNotifications: false,
      savePaymentMethod: false,
      autopay: false,
      incodeAccountNo: [""],
      zone: [""],
      selectedAccount: {},
      mailingAddress: {},
      mailingCity: {},
      mailingState: {},
      mailingZipcode: {},
      serviceAddress: {},
      serviceCity: {},
      serviceState: {},
      serviceZipcode: {},
      useServiceAddrForMailing: false,
      checkBillReminder: false,
      lateFeeWarning: false,
      lateNotice: false,
      disconnectWarning: false,
      // flag_use: true,
      emailNotification: [""],
      phoneNotification: [""],
    });

    this.adminEditUserFormGroup = this.fb.group({
      firstName: ["", Validators.required],
      homePhone: [""],
      mobilePhone: [""],
      email: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.logged_in_user = JSON.parse(window.localStorage.current_user);

    if (window.location.href.includes("/es/")) {
      this.submitSwalAlertTitle = "Desea guardar los cambios?";
      this.editSuccessAlertTitle = "El Usuario ha sido editado";
      this.editFailureAlertTitle = "Hubo un error";
      this.editFailureAlertText = "Porfavor, intente mas tarde";
      this.successAlertTitle = "Se ha enviado un link";
      this._400failureAlertTitle =
        "El correo ingresado no ha sido encontrado o no es valido";
      this.failureAlertTitle = "Lo sentimos, huno un error";
      this.selectAccountPlaceholder = "Seleccione una Cuenta";
    }

    this.zoneService.getZones().subscribe((data) => {
      this.zones = data;
    });

    this.CS.getCronReminder(this.user.id).subscribe((info) => {
      if (info.length > 0) {
        this.updateUserFormGroup.patchValue({
          checkBillReminder: info[0].cron_reminder["bill-reminder"],
          lateFeeWarning: info[0].cron_reminder["late-fee-warning"],
          lateNotice: info[0].cron_reminder["late-notice"],
          disconnectWarning: info[0].cron_reminder["disconnect-warning"],
          emailNotification: info[0].email,
          phoneNotification: info[0].phone,
        });
      }
    });
  }

  ngOnChanges() {}

  ngAfterViewInit() {
    // Add chosen to select elements
    $(".chosen-select").chosen({ width: "100%" });
  }

  get emailAddress() {
    return this.updateUserFormGroup.value.email || "";
  }

  subscribeToSettings() {
    this.accounts = this.user.accounts;
    if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id == 2 ||
      this.user.user_type_id == 4 ||
      this.user.user_type_id == 5 ||
      this.user.user_type_id == 6 ||
      this.user.user_type_id == 7
    ) {
      this.adminEditUserFormGroup.patchValue({
        firstName: this.user.user_info.first_name,
        homePhone: this.user.user_info.home_phone,
        mobilePhone: this.user.user_info.mobile_phone,
        email: this.user.email,
      });
    }

    if (this.user.user_type_id == 3) {
      this.updateUserFormGroup.patchValue({
        firstName: this.user.user_info.first_name,
        lastName: this.user.user_info.last_name || "",
        dateOfBirth: this.user.user_info.date_of_birth
          ? moment(this.user.user_info.date_of_birth).format("MM/DD/YYYY")
          : null,
        driverLicenseNumber: this.user.user_info.driver_license_number,
        homePhone: this.user.user_info.home_phone,
        mobilePhone: this.user.user_info.mobile_phone,
        email: this.user.email,
      });

      this.flag_use = this.accounts[0].flag_use;

      this.updateUserFormGroup.patchValue({
        selectedAccount: this.accounts[0],
        incodeAccountNo: this.accounts[0].incode_account_no || "",
        zone: this.accounts[0].zone_id || "",
        mailingAddress:
          this.accounts[0].mailing_address.street_address || "N/A",
        mailingCity: this.accounts[0].mailing_address.city || "N/A",
        mailingState: this.accounts[0].mailing_address.state || "N/A",
        mailingZipcode: this.accounts[0].mailing_address.zip_code || "N/A",
        serviceAddress:
          this.accounts[0].service_address.street_address || "N/A",
        serviceCity: this.accounts[0].service_address.city || "N/A",
        serviceState: this.accounts[0].service_address.state || "N/A",
        serviceZipcode: this.accounts[0].service_address.zip_code || "N/A",
        useServiceAddrForMailing:
          this.accounts[0].mailing_address.street_address ==
          this.accounts[0].service_address.street_address
            ? true
            : false,
      });
    }

    this.userService
      .getSettingsById(this.user.id)
      .subscribe((data: UserSettings) => {
        this.userSettings = data;
        this.updateUserFormGroup.patchValue({
          emailNotifications: this.userSettings.email_notifications,
          savePaymentMethod: this.userSettings.save_payment_method,
          autopay: this.userSettings.autopay,
        });
      });
  }

  changeAccount() {
    this.updateUserFormGroup.patchValue({
      incodeAccountNo:
        this.updateUserFormGroup.value.selectedAccount.incode_account_no,
      mailingAddress:
        this.updateUserFormGroup.value.selectedAccount.mailing_address
          .street_address,
      mailingCity:
        this.updateUserFormGroup.value.selectedAccount.mailing_address.city,
      mailingState:
        this.updateUserFormGroup.value.selectedAccount.mailing_address.state,
      mailingZipcode:
        this.updateUserFormGroup.value.selectedAccount.mailing_address.zip_code,
      serviceAddress:
        this.updateUserFormGroup.value.selectedAccount.service_address
          .street_address,
      serviceCity:
        this.updateUserFormGroup.value.selectedAccount.service_address.city,
      serviceState:
        this.updateUserFormGroup.value.selectedAccount.service_address.state,
      serviceZipcode:
        this.updateUserFormGroup.value.selectedAccount.service_address.zip_code,
    });
  }

  setMobileNumber(number: string) {
    let mobileNumber = number.trim().replace(/[()\-_\s]/g, "");
    if (this.user.user_type_id == 3) {
      this.updateUserFormGroup.patchValue({
        mobilePhone: mobileNumber.length == 10 ? mobileNumber : null,
      });
    } else if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id == 2 ||
      this.user.user_type_id == 4 ||
      this.user.user_type_id == 5 ||
      this.user.user_type_id == 6 ||
      this.user.user_type_id == 7
    ) {
      this.adminEditUserFormGroup.patchValue({
        mobilePhone: mobileNumber.length == 10 ? mobileNumber : null,
      });
    }
  }

  setHomeNumber(number: string) {
    let mobileNumber = number.trim().replace(/[()\-_\s]/g, "");
    if (this.user.user_type_id == 3) {
      this.updateUserFormGroup.patchValue({
        homePhone: mobileNumber.length == 10 ? mobileNumber : null,
      });
    } else if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id == 2 ||
      this.user.user_type_id == 4 ||
      this.user.user_type_id == 5 ||
      this.user.user_type_id == 6 ||
      this.user.user_type_id == 7
    ) {
      this.adminEditUserFormGroup.patchValue({
        homePhone: mobileNumber.length == 10 ? mobileNumber : null,
      });
    }
  }

  toggleMailingAddress() {
    if (this.updateUserFormGroup.value.useServiceAddrForMailing) {
      this.disableMailingAddress = true;
      this.updateUserFormGroup.controls["mailingAddress"].disable();
      this.updateUserFormGroup.controls["mailingCity"].disable();
      this.updateUserFormGroup.controls["mailingState"].disable();
      this.updateUserFormGroup.controls["mailingZipcode"].disable();
    } else {
      this.disableMailingAddress = false;
      this.updateUserFormGroup.controls["mailingAddress"].enable();
      this.updateUserFormGroup.controls["mailingCity"].enable();
      this.updateUserFormGroup.controls["mailingState"].enable();
      this.updateUserFormGroup.controls["mailingZipcode"].enable();
    }
    this.updateMailingAddress();
  }

  updateMailingAddress() {
    if (this.updateUserFormGroup.value.useServiceAddrForMailing) {
      this.updateUserFormGroup.patchValue({
        mailingAddress: this.updateUserFormGroup.value.serviceAddress,
        mailingCity: this.updateUserFormGroup.value.serviceCity,
        mailingState: this.updateUserFormGroup.value.serviceState,
        mailingZipcode: this.updateUserFormGroup.value.serviceZipcode,
      });
    }
  }

  submitEdit() {
    this.loading = true;
    let userInfo = null;
    let user = null;
    let userSettings = null;
    let account;

    if (this.user.user_type_id == 3) {
      userInfo = {
        first_name: this.updateUserFormGroup.value.firstName,
        last_name: this.updateUserFormGroup.value.lastName || "",
        // birthday: !this.updateUserFormGroup.value.dateOfBirth ? (this.updateUserFormGroup.value.dateOfBirth.toLowerCase() == 'invalid date' ? null : this.updateUserFormGroup.value.dateOfBirth) : this.updateUserFormGroup.value.dateOfBirth,
        birthday: this.updateUserFormGroup.value.dateOfBirth,
        home_phone: this.updateUserFormGroup.value.homePhone,
        mobile_phone: this.updateUserFormGroup.value.mobilePhone,
        drivers_license: this.updateUserFormGroup.value.driverLicenseNumber,
        drivers_license_url: this.user.user_info.drivers_license_url || null,
      };
      user = {
        email: this.updateUserFormGroup.value.email || "",
        password: this.user.password || "",
        user_type_id: this.user.user_type_id,
        active: this.user.active,
      };
      userSettings = {
        autopay: this.updateUserFormGroup.value.autopay || false,
        save_payment_method:
          this.updateUserFormGroup.value.savePaymentMethod || false,
        email_notifications:
          this.updateUserFormGroup.value.emailNotifications || false,
      };

      account = {
        zone_id: this.updateUserFormGroup.value.zone,
      };

      let dataReminder = {
        data: {
          "bill-reminder": this.updateUserFormGroup.value.checkBillReminder,
          "late-fee-warning": this.updateUserFormGroup.value.lateFeeWarning,
          "late-notice": this.updateUserFormGroup.value.lateNotice,
          "disconnect-warning":
            this.updateUserFormGroup.value.disconnectWarning,
        },
        email: this.updateUserFormGroup.value.emailNotification,
        phone: this.updateUserFormGroup.value.phoneNotification,
      };

      this.userService
        .updateUserById(this.user.id, user)
        .pipe(
          mergeMap((e) =>
            this.userService.updateUserInfoById(this.user.id, userInfo)
          ),
          mergeMap((e) =>
            this.userService.updateSettingsById(this.user.id, userSettings)
          ),
          mergeMap((e) =>
            this.accountService.updateAccountZoneById(
              this.updateUserFormGroup.value.selectedAccount.id,
              account
            )
          ),
          mergeMap((e) => this.CS.setReminders(this.user.id, dataReminder))
        )
        .subscribe(
          (data) => {
            this.loading = false;
            this.editSuccessSwal.show();
          },
          (err) => {
            console.log("error: ", err);
            this.loading = false;
            if (err.status == 422) {
              this.editFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("mobile_phone") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Mobile phone must only include numbers";

              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Telefono movil solo debe incluir numeros";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("home_phone") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Home Phone must only include numbers";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Telefono Casa solo debe incluir numeros";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("drivers_license") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Driver license must only include numbers";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Numero de licencia solo debe incluir numeros";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("birthday")
            ) {
              this.editDataFailureSwal.title = "Birthday must be a valid date";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Fecha de Nacimiento debe ser una fecha valida";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 500 &&
              err.message.message &&
              err.message.message.toLowerCase().includes("users_email_key")
            ) {
              this.editDataFailureSwal.title = "Email is already in use";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title = "El correo ya esta en uso";
              }
              this.editDataFailureSwal.show();
            } else if (err.status == 400) {
              this.editDataFailureSwal.title = err.message;
              this.editDataFailureSwal.show();
            } else {
              this.editFailureSwal.show();
            }
          }
        );
    } else if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id == 2 ||
      this.user.user_type_id == 4 ||
      this.user.user_type_id == 5 ||
      this.user.user_type_id == 6 ||
      this.user.user_type_id == 7
    ) {
      userInfo = {
        first_name: this.adminEditUserFormGroup.value.firstName,
        home_phone: this.adminEditUserFormGroup.value.homePhone || null,
        mobile_phone: this.adminEditUserFormGroup.value.mobilePhone || null,
      };

      user = {
        email: this.adminEditUserFormGroup.value.email,
        password: this.user.password || "",
        user_type_id: this.user.user_type_id,
        active: this.user.active,
      };

      userSettings = {
        autopay: null,
        save_payment_method: null,
        email_notifications: null,
      };

      this.userService
        .updateUserById(this.user.id, user)
        .pipe(
          mergeMap((e) =>
            this.userService.updateAdminUserInfoById(this.user.id, userInfo)
          ),
          mergeMap((e) =>
            this.userService.updateSettingsById(this.user.id, userSettings)
          )
          // mergeMap(e => this.accountService.updateAccountById(account.id, account))
        )
        .subscribe(
          (data) => {
            this.loading = false;
            this.editSuccessSwal.show();
          },
          (err) => {
            console.log("error: ", err);
            this.loading = false;
            if (err.status == 422) {
              this.editFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("mobile_phone") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Mobile phone must only include numbers";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Teléfono Móvil debe solo incluir números";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("home_phone") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Home Phone must only include numbers";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Teléfono de Casa debe solo incluir números";
              }
              this.editDataFailureSwal.show();
            } else if (
              err.status == 400 &&
              err.message.toLowerCase().includes("drivers_license") &&
              err.message.toLowerCase().includes("pattern")
            ) {
              this.editDataFailureSwal.title =
                "Driver license must only include numbers";
              if (window.location.href.includes("/es/")) {
                this.editDataFailureSwal.title =
                  "Licencia de Conducción debe solo incluir números";
              }
              this.editDataFailureSwal.show();
            } else if (err.status == 400) {
              this.editDataFailureSwal.title = err.message;
              this.editDataFailureSwal.show();
            } else {
              this.editFailureSwal.show();
            }
          }
        );
    }
  }

  emailLink() {
    this.loading = true;
    let email = "";

    if (this.user.user_type_id == 3) {
      email = this.updateUserFormGroup.value.email;
    } else if (
      this.user.user_type_id == 1 ||
      this.user.user_type_id == 2 ||
      this.user.user_type_id == 4 ||
      this.user.user_type_id == 5 ||
      this.user.user_type_id == 6 ||
      this.user.user_type_id == 7
    ) {
      email = this.adminEditUserFormGroup.value.email;
    }

    this.forgotPasswordService.emailResetPasswordLink(email).subscribe(
      (response) => {
        this.loading = false;
        if (response.status === 200) {
          this.sentMessage = "";
          this.successSwal.show();
        }
      },
      (error) => {
        this.loading = false;
        if (error.message) {
          if (error.message.code === 400) this.sentMessage = "";
          this.notFoundFailureSwal.show();
        } else {
          this.sentMessage = "";
          this.failureSwal.show();
        }
      }
    );
  }

  syncAddressesWithIncode() {
    this.loading = true;
    let selectedAccount = this.user.accounts.filter(
      (account) =>
        account.incode_account_no ==
        this.updateUserFormGroup.controls.incodeAccountNo.value
    );

    this.addressService
      .syncAddressesWithIncode(
        selectedAccount[0].incode_account_no,
        selectedAccount[0].mailing_address.id,
        selectedAccount[0].service_address.id
      )
      .subscribe(
        (response: any) => {
          this.loading = false;

          this.updateUserFormGroup.patchValue({
            mailingAddress: response.mailingAddress[0].street_address,
            mailingCity: response.mailingAddress[0].city,
            mailingState: response.mailingAddress[0].state,
            mailingZipcode: response.mailingAddress[0].zip_code,
            serviceAddress: response.serviceAddress[0].street_address,
            serviceCity: response.serviceAddress[0].city,
            serviceState: response.serviceAddress[0].state,
            serviceZipcode: response.serviceAddress[0].zip_code,
          });
          this.successSwal.title = "Success";
          this.successSwal.text = "Addresses were sync successfully";
          this.successSwal.show();
        },
        (error) => {
          this.loading = false;
          this.failureSwal.title = "Error";
          this.failureSwal.text =
            "Something went wrong, please try again later";
          this.failureSwal.show();
          console.log("ERROR SYNCING ADDRESS: ", error);
        }
      );
  }

  setBirthDate() {
    setTimeout(function () {
      var d = $("bs-datepicker-container, bs-daterangepicker-container").css(
        "z-index",
        "9999"
      );
    }, 0);
  }

  confirm() {
    this.bsModalRef.hide();
    this.editUserModalClosed.emit(true);
  }

  showRequestID() {
    const raModalRef = this.bsModalService.show(
      RequestIDComponent,
      Object.assign({}, { class: "gray modal-md inmodal" })
    );
    raModalRef.content.setRequestInfo(this.user.user_info.driver_license_url);
  }

  showRequestRA() {
    const raModalRef = this.bsModalService.show(
      RequestIDComponent,
      Object.assign({}, { class: "gray modal-md inmodal" })
    );
    raModalRef.content.setRequestInfo(
      this.updateUserFormGroup.value.selectedAccount.deed_rental_url
    );
  }

  getBalance() {
    this.loading = true;
    this.accountService
      .getBalanceIncode(
        this.updateUserFormGroup.value.selectedAccount.incode_account_no
      )
      .subscribe((data) => {
        this.loading = false;
        this.successSwal.title = "Success";
        this.successSwal.text = "Balance successfully retrieved";
        this.successSwal.show();
      });
  }

  changeFlagUse(event) {
    if (!this.updateUserFormGroup.value.selectedAccount.id) {
      return;
    }

    this.accountService
      .getAccountFlagUse(
        this.updateUserFormGroup.value.selectedAccount.id,
        event.target.checked
      )
      .subscribe((data) => {});
  }

  sendOtp(type: string) {
    // (832) 324-5843
    if (type == "phone") {
      if (
        this.adminEditUserFormGroup.value.mobilePhone == null ||
        this.adminEditUserFormGroup.value.mobilePhone == ""
      ) {
        this.editDataFailureSwal.title = "Please enter a valid phone number";
        this.editDataFailureSwal.show();
        return;
      }
    }

    this.userService.sendOtp({ user_id: this.user.id, type }).subscribe(
      (data: any) => {
        this.userSettings = data.data[0];
      },
      (error) => {
        this.editDataFailureSwal.title = error.message;
        this.editDataFailureSwal.show();
      }
    );
  }
}
